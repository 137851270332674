import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { api } from "api";

const initialState = {
  data: [],
  loading: true,
  error: false,
  errorMessage: undefined,
};

export const beneficiariesSlice = createSlice({
  name: "beneficiaries",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all beneficiaries
    builder.addCase(getAllBeneficiaries.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllBeneficiaries.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
    // add new beneficiary
    builder.addCase(addNewBeneficiary.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.data = [action.payload.data, ...state.data];
      }
      state.loading = false;
    });
    builder.addCase(addNewBeneficiary.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
    // delete new beneficiary
    builder.addCase(deleteBeneficiary.fulfilled, (state, action) => {
      state.loading = false;
      state.data = state.data.filter((b) => b.uuid !== action.meta.arg);
    });
    builder.addCase(deleteBeneficiary.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const getAllBeneficiaries = createAsyncThunk(
  "getAllBeneficiaries",
  async () => {
    const { data } = await api.get("/ziyl/getBeneficiaryfinxp");
    return data;
  }
);

export const addNewBeneficiary = createAsyncThunk(
  "addNewBeneficiary",
  async (params) => {
    const { data } = await api.post("/ziyl/createbeneficiaryfinxp", params);
    return data;
  }
);

export const deleteBeneficiary = createAsyncThunk(
  "deleteBeneficiary",
  async (uuid) => {
    const { data } = await api.delete(`/ziyl/deleteBeneficiaryfinxp/${uuid}`);
    return data;
  }
);

export default beneficiariesSlice.reducer;
