import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const initialState = {
  data: [],
  loading: true,
  error: false,
  errorMessage: undefined,
};

export const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get transactions
    builder.addCase(getTransactions.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getTransactions.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const getTransactions = createAsyncThunk(
  "getTransactions",
  async (params) => {
    const { data } = await api.post("/ziyl/getTransactionsV2finxp", params);
    return data;
  }
);

export default transactionSlice.reducer;
