import { Fragment } from "react";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { useAtom } from "jotai";

import { isLoadingBackground } from "utils/globalStates";
import useStyles from "./styles";

const LoadableBackground = () => {
  const classes = useStyles();
  const [isLoadableBackground, setLoadableBackground] =
    useAtom(isLoadingBackground);

  return (
    <Fragment>
      {isLoadableBackground ? (
        <Backdrop
          classes={{ root: classes.backdrop }}
          open={isLoadableBackground}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
    </Fragment>
  );
};

export default LoadableBackground;
