import { useMediaQuery } from "@mui/material";

export function useViewPort() {
  const smallMobile = "320px"
  const largeMobile = "425px"
  const tablet = "768px"
  const desktop = "1024px"

  return {
    isSmallMobile: useMediaQuery(`(min-width: ${smallMobile})`),
    isLargeMobile: useMediaQuery(`(min-width: ${largeMobile})`),
    isTablet: useMediaQuery(`(min-width: ${tablet})`),
    isDesktop: useMediaQuery(`(min-width: ${desktop})`),
  }
}
