import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const beneficiaryV2 = createApi({
  reducerPath: "beneficiaryV2",
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_APIURL,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem("accessToken");
      const tokenZiyl = localStorage.getItem("token_ziyl");
      if (accessToken && tokenZiyl) {
        headers.set("Content-Type", "application/json");
        headers.set("AuthorizationFinxp", `Bearer ${accessToken}`);
        headers.set("Authorization", `Bearer ${tokenZiyl}`);
        return headers;
      }
    },
  }),
  keepUnusedDataFor: 30,
  tagTypes: ["BeneficiaryV2"],
  endpoints: (builder) => ({
    getBeneficiaries: builder.query({
      query: () => ({
        url: `/ziyl/getBeneficiaryfinxp`,
      }),
      invalidatesTags: ["beneficiaryV2"],
    }),
    searchBeneficiaries: builder.query({
      query: (data) => ({
        url: `/ziyl/getBeneficiaryfinxp`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["beneficiaryV2"],
    }),

    addBeneficiary: builder.mutation({
      query: ({ beneficiaryData }) => ({
        url: `/ziyl/createbeneficiaryfinxp`,
        method: "POST",
        body: beneficiaryData,
      }),
      invalidatesTags: ["beneficiaryV2"],
    }),
    deleteBeneficiary: builder.query({
      query: ({ beneficiaryId }) => {
        return {
          url: `/ziyl/deleteBeneficiaryfinxp/${beneficiaryId}`,
          method: "DELETE",
        }
      },
      invalidatesTags: ["beneficiaryV2"],
    }),
  }),
});

export const {
    useGetBeneficiariesQuery,
    useSearchBeneficiariesQuery,
    useAddBeneficiaryMutation,
    useLazyGetBeneficiariesQuery,
    useLazySearchBeneficiariesQuery,
    useLazyDeleteBeneficiaryQuery,
  } =
  beneficiaryV2;
