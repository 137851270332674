import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  root: {
    height: "100%",
  },
  palette: {
    mediumGray: "#b4b7bc",
    mediumGray2: "#696f7a",
    mediumGrayLighter: "#b3b3b3",
    buttonTextColorPrimary: "#e7038e",
    buttonBackgroundColorPrimary: "#fff0f9",
    buttonTextColorSecondary: "#b3b3b3",
    buttonBackgroundColorSecondary: "#f5f4f4",
    accentBlue: "#086afb",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        html: {
          fontSize: "100%",
          height: "100%",
        },
        body: {
          height: "100%",
        },
      },
    },
  },
  typography: {
    regularFont: "1.125rem", // 18px
    semiRegularFont: "1rem", // 16px
    smallFont: "0.875rem", // 14px
    verySmallFont: ".813rem", // 13px
  },
});

export default theme;
