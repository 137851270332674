const ISO3116CountryList = [
  {
    numeric: "4",
    alpha2: "AF",
    alpha3: "AFG",
    name: "Afghanistan",
    nationality: "Afghan",
  },
  {
    numeric: "248",
    alpha2: "AX",
    alpha3: "ALA",
    name: "Åland Islands",
    nationality: "Åland Island",
  },
  {
    numeric: "8",
    alpha2: "AL",
    alpha3: "ALB",
    name: "Albania",
    nationality: "Albanian",
  },
  {
    numeric: "12",
    alpha2: "DZ",
    alpha3: "DZA",
    name: "Algeria",
    nationality: "Algerian",
  },
  {
    numeric: "16",
    alpha2: "AS",
    alpha3: "ASM",
    name: "American Samoa",
    nationality: "American Samoan",
  },
  {
    numeric: "20",
    alpha2: "AD",
    alpha3: "AND",
    name: "Andorra",
    nationality: "Andorran",
  },
  {
    numeric: "24",
    alpha2: "AO",
    alpha3: "AGO",
    name: "Angola",
    nationality: "Angolan",
  },
  {
    numeric: "660",
    alpha2: "AI",
    alpha3: "AIA",
    name: "Anguilla",
    nationality: "Anguillan",
  },
  {
    numeric: "10",
    alpha2: "AQ",
    alpha3: "ATA",
    name: "Antarctica",
    nationality: "Antarctic",
  },
  {
    numeric: "28",
    alpha2: "AG",
    alpha3: "ATG",
    name: "Antigua and Barbuda",
    nationality: "Antiguan or Barbudan",
  },
  {
    numeric: "32",
    alpha2: "AR",
    alpha3: "ARG",
    name: "Argentina",
    nationality: "Argentine",
  },
  {
    numeric: "51",
    alpha2: "AM",
    alpha3: "ARM",
    name: "Armenia",
    nationality: "Armenian",
  },
  {
    numeric: "533",
    alpha2: "AW",
    alpha3: "ABW",
    name: "Aruba",
    nationality: "Aruban",
  },
  {
    numeric: "36",
    alpha2: "AU",
    alpha3: "AUS",
    name: "Australia",
    nationality: "Australian",
  },
  {
    numeric: "40",
    alpha2: "AT",
    alpha3: "AUT",
    name: "Austria",
    nationality: "Austrian",
  },
  {
    numeric: "31",
    alpha2: "AZ",
    alpha3: "AZE",
    name: "Azerbaijan",
    nationality: "Azerbaijani, Azeri",
  },
  {
    numeric: "44",
    alpha2: "BS",
    alpha3: "BHS",
    name: "Bahamas",
    nationality: "Bahamian",
  },
  {
    numeric: "48",
    alpha2: "BH",
    alpha3: "BHR",
    name: "Bahrain",
    nationality: "Bahraini",
  },
  {
    numeric: "50",
    alpha2: "BD",
    alpha3: "BGD",
    name: "Bangladesh",
    nationality: "Bangladeshi",
  },
  {
    numeric: "52",
    alpha2: "BB",
    alpha3: "BRB",
    name: "Barbados",
    nationality: "Barbadian",
  },
  {
    numeric: "112",
    alpha2: "BY",
    alpha3: "BLR",
    name: "Belarus",
    nationality: "Belarusian",
  },
  {
    numeric: "56",
    alpha2: "BE",
    alpha3: "BEL",
    name: "Belgium",
    nationality: "Belgian",
  },
  {
    numeric: "84",
    alpha2: "BZ",
    alpha3: "BLZ",
    name: "Belize",
    nationality: "Belizean",
  },
  {
    numeric: "204",
    alpha2: "BJ",
    alpha3: "BEN",
    name: "Benin",
    nationality: "Beninese, Beninois",
  },
  {
    numeric: "60",
    alpha2: "BM",
    alpha3: "BMU",
    name: "Bermuda",
    nationality: "Bermudian, Bermudan",
  },
  {
    numeric: "64",
    alpha2: "BT",
    alpha3: "BTN",
    name: "Bhutan",
    nationality: "Bhutanese",
  },
  {
    numeric: "68",
    alpha2: "BO",
    alpha3: "BOL",
    name: "Bolivia",
    nationality: "Bolivian",
  },
  {
    numeric: "535",
    alpha2: "BQ",
    alpha3: "BES",
    name: "Bonaire, Sint Eustatius and Saba",
    nationality: "Bonaire",
  },
  {
    numeric: "70",
    alpha2: "BA",
    alpha3: "BIH",
    name: "Bosnia and Herzegovina",
    nationality: "Bosnian or Herzegovinian",
  },
  {
    numeric: "72",
    alpha2: "BW",
    alpha3: "BWA",
    name: "Botswana",
    nationality: "Motswana, Botswanan",
  },
  {
    numeric: "74",
    alpha2: "BV",
    alpha3: "BVT",
    name: "Bouvet Island",
    nationality: "Bouvet Island",
  },
  {
    numeric: "76",
    alpha2: "BR",
    alpha3: "BRA",
    name: "Brazil",
    nationality: "Brazilian",
  },
  {
    numeric: "86",
    alpha2: "IO",
    alpha3: "IOT",
    name: "British Indian Ocean Territory",
    nationality: "BIOT",
  },
  {
    numeric: "96",
    alpha2: "BN",
    alpha3: "BRN",
    name: "Brunei Darussalam",
    nationality: "Bruneian",
  },
  {
    numeric: "100",
    alpha2: "BG",
    alpha3: "BGR",
    name: "Bulgaria",
    nationality: "Bulgarian",
  },
  {
    numeric: "854",
    alpha2: "BF",
    alpha3: "BFA",
    name: "Burkina Faso",
    nationality: "Burkinabé",
  },
  {
    numeric: "108",
    alpha2: "BI",
    alpha3: "BDI",
    name: "Burundi",
    nationality: "Burundian",
  },
  {
    numeric: "132",
    alpha2: "CV",
    alpha3: "CPV",
    name: "Cabo Verde",
    nationality: "Cabo Verdean",
  },
  {
    numeric: "116",
    alpha2: "KH",
    alpha3: "KHM",
    name: "Cambodia",
    nationality: "Cambodian",
  },
  {
    numeric: "120",
    alpha2: "CM",
    alpha3: "CMR",
    name: "Cameroon",
    nationality: "Cameroonian",
  },
  {
    numeric: "124",
    alpha2: "CA",
    alpha3: "CAN",
    name: "Canada",
    nationality: "Canadian",
  },
  {
    numeric: "136",
    alpha2: "KY",
    alpha3: "CYM",
    name: "Cayman Islands",
    nationality: "Caymanian",
  },
  {
    numeric: "140",
    alpha2: "CF",
    alpha3: "CAF",
    name: "Central African Republic",
    nationality: "Central African",
  },
  {
    numeric: "148",
    alpha2: "TD",
    alpha3: "TCD",
    name: "Chad",
    nationality: "Chadian",
  },
  {
    numeric: "152",
    alpha2: "CL",
    alpha3: "CHL",
    name: "Chile",
    nationality: "Chilean",
  },
  {
    numeric: "156",
    alpha2: "CN",
    alpha3: "CHN",
    name: "China",
    nationality: "Chinese",
  },
  {
    numeric: "162",
    alpha2: "CX",
    alpha3: "CXR",
    name: "Christmas Island",
    nationality: "Christmas Island",
  },
  {
    numeric: "166",
    alpha2: "CC",
    alpha3: "CCK",
    name: "Cocos (Keeling) Islands",
    nationality: "Cocos Island",
  },
  {
    numeric: "170",
    alpha2: "CO",
    alpha3: "COL",
    name: "Colombia",
    nationality: "Colombian",
  },
  {
    numeric: "174",
    alpha2: "KM",
    alpha3: "COM",
    name: "Comoros",
    nationality: "Comoran, Comorian",
  },
  {
    numeric: "178",
    alpha2: "CG",
    alpha3: "COG",
    name: "Congo (Republic of the)",
    nationality: "Congolese",
  },
  {
    numeric: "180",
    alpha2: "CD",
    alpha3: "COD",
    name: "Congo, The Democratic Republic",
    nationality: "Congolese",
  },
  {
    numeric: "184",
    alpha2: "CK",
    alpha3: "COK",
    name: "Cook Islands",
    nationality: "Cook Island",
  },
  {
    numeric: "188",
    alpha2: "CR",
    alpha3: "CRI",
    name: "Costa Rica",
    nationality: "Costa Rican",
  },
  {
    numeric: "384",
    alpha2: "CI",
    alpha3: "CIV",
    name: "Côte d'Ivoire",
    nationality: "Ivorian",
  },
  {
    numeric: "191",
    alpha2: "HR",
    alpha3: "HRV",
    name: "Croatia",
    nationality: "Croatian",
  },
  {
    numeric: "192",
    alpha2: "CU",
    alpha3: "CUB",
    name: "Cuba",
    nationality: "Cuban",
  },
  {
    numeric: "531",
    alpha2: "CW",
    alpha3: "CUW",
    name: "Curaçao",
    nationality: "Curaçaoan",
  },
  {
    numeric: "196",
    alpha2: "CY",
    alpha3: "CYP",
    name: "Cyprus",
    nationality: "Cypriot",
  },
  {
    numeric: "203",
    alpha2: "CZ",
    alpha3: "CZE",
    name: "Czech Republic",
    nationality: "Czech",
  },
  {
    numeric: "208",
    alpha2: "DK",
    alpha3: "DNK",
    name: "Denmark",
    nationality: "Danish",
  },
  {
    numeric: "262",
    alpha2: "DJ",
    alpha3: "DJI",
    name: "Djibouti",
    nationality: "Djiboutian",
  },
  {
    numeric: "212",
    alpha2: "DM",
    alpha3: "DMA",
    name: "Dominica",
    nationality: "Dominican",
  },
  {
    numeric: "214",
    alpha2: "DO",
    alpha3: "DOM",
    name: "Dominican Republic",
    nationality: "Dominican",
  },
  {
    numeric: "218",
    alpha2: "EC",
    alpha3: "ECU",
    name: "Ecuador",
    nationality: "Ecuadorian",
  },
  {
    numeric: "818",
    alpha2: "EG",
    alpha3: "EGY",
    name: "Egypt",
    nationality: "Egyptian",
  },
  {
    numeric: "222",
    alpha2: "SV",
    alpha3: "SLV",
    name: "El Salvador",
    nationality: "Salvadoran",
  },
  {
    numeric: "226",
    alpha2: "GQ",
    alpha3: "GNQ",
    name: "Equatorial Guinea",
    nationality: "Equatorial Guinean, Equatoguinean",
  },
  {
    numeric: "232",
    alpha2: "ER",
    alpha3: "ERI",
    name: "Eritrea",
    nationality: "Eritrean",
  },
  {
    numeric: "233",
    alpha2: "EE",
    alpha3: "EST",
    name: "Estonia",
    nationality: "Estonian",
  },
  {
    numeric: "231",
    alpha2: "ET",
    alpha3: "ETH",
    name: "Ethiopia",
    nationality: "Ethiopian",
  },
  {
    numeric: "238",
    alpha2: "FK",
    alpha3: "FLK",
    name: "Falkland Islands (Malvinas)",
    nationality: "Falkland Island",
  },
  {
    numeric: "234",
    alpha2: "FO",
    alpha3: "FRO",
    name: "Faroe Islands",
    nationality: "Faroese",
  },
  {
    numeric: "242",
    alpha2: "FJ",
    alpha3: "FJI",
    name: "Fiji",
    nationality: "Fijian",
  },
  {
    numeric: "246",
    alpha2: "FI",
    alpha3: "FIN",
    name: "Finland",
    nationality: "Finnish",
  },
  {
    numeric: "250",
    alpha2: "FR",
    alpha3: "FRA",
    name: "France",
    nationality: "French",
  },
  {
    numeric: "254",
    alpha2: "GF",
    alpha3: "GUF",
    name: "French Guiana",
    nationality: "French Guianese",
  },
  {
    numeric: "258",
    alpha2: "PF",
    alpha3: "PYF",
    name: "French Polynesia",
    nationality: "French Polynesian",
  },
  {
    numeric: "260",
    alpha2: "TF",
    alpha3: "ATF",
    name: "French Southern Territories",
    nationality: "French Southern Territories",
  },
  {
    numeric: "266",
    alpha2: "GA",
    alpha3: "GAB",
    name: "Gabon",
    nationality: "Gabonese",
  },
  {
    numeric: "270",
    alpha2: "GM",
    alpha3: "GMB",
    name: "Gambia",
    nationality: "Gambian",
  },
  {
    numeric: "268",
    alpha2: "GE",
    alpha3: "GEO",
    name: "Georgia",
    nationality: "Georgian",
  },
  {
    numeric: "276",
    alpha2: "DE",
    alpha3: "DEU",
    name: "Germany",
    nationality: "German",
  },
  {
    numeric: "288",
    alpha2: "GH",
    alpha3: "GHA",
    name: "Ghana",
    nationality: "Ghanaian",
  },
  {
    numeric: "292",
    alpha2: "GI",
    alpha3: "GIB",
    name: "Gibraltar",
    nationality: "Gibraltar",
  },
  {
    numeric: "300",
    alpha2: "GR",
    alpha3: "GRC",
    name: "Greece",
    nationality: "Greek, Hellenic",
  },
  {
    numeric: "304",
    alpha2: "GL",
    alpha3: "GRL",
    name: "Greenland",
    nationality: "Greenlandic",
  },
  {
    numeric: "308",
    alpha2: "GD",
    alpha3: "GRD",
    name: "Grenada",
    nationality: "Grenadian",
  },
  {
    numeric: "312",
    alpha2: "GP",
    alpha3: "GLP",
    name: "Guadeloupe",
    nationality: "Guadeloupe",
  },
  {
    numeric: "316",
    alpha2: "GU",
    alpha3: "GUM",
    name: "Guam",
    nationality: "Guamanian, Guambat",
  },
  {
    numeric: "320",
    alpha2: "GT",
    alpha3: "GTM",
    name: "Guatemala",
    nationality: "Guatemalan",
  },
  {
    numeric: "831",
    alpha2: "GG",
    alpha3: "GGY",
    name: "Guernsey",
    nationality: "Channel Island",
  },
  {
    numeric: "324",
    alpha2: "GN",
    alpha3: "GIN",
    name: "Guinea",
    nationality: "Guinean",
  },
  {
    numeric: "624",
    alpha2: "GW",
    alpha3: "GNB",
    name: "Guinea-Bissau",
    nationality: "Bissau-Guinean",
  },
  {
    numeric: "328",
    alpha2: "GY",
    alpha3: "GUY",
    name: "Guyana",
    nationality: "Guyanese",
  },
  {
    numeric: "332",
    alpha2: "HT",
    alpha3: "HTI",
    name: "Haiti",
    nationality: "Haitian",
  },
  {
    numeric: "334",
    alpha2: "HM",
    alpha3: "HMD",
    name: "Heard Island and McDonald Islands",
    nationality: "Heard Island or McDonald Islands",
  },
  {
    numeric: "336",
    alpha2: "VA",
    alpha3: "VAT",
    name: "Vatican City State",
    nationality: "Vatican",
  },
  {
    numeric: "340",
    alpha2: "HN",
    alpha3: "HND",
    name: "Honduras",
    nationality: "Honduran",
  },
  {
    numeric: "344",
    alpha2: "HK",
    alpha3: "HKG",
    name: "Hong Kong",
    nationality: "Hong Kong, Hong Kongese",
  },
  {
    numeric: "348",
    alpha2: "HU",
    alpha3: "HUN",
    name: "Hungary",
    nationality: "Hungarian, Magyar",
  },
  {
    numeric: "352",
    alpha2: "IS",
    alpha3: "ISL",
    name: "Iceland",
    nationality: "Icelandic",
  },
  {
    numeric: "356",
    alpha2: "IN",
    alpha3: "IND",
    name: "India",
    nationality: "Indian",
  },
  {
    numeric: "360",
    alpha2: "ID",
    alpha3: "IDN",
    name: "Indonesia",
    nationality: "Indonesian",
  },
  {
    numeric: "364",
    alpha2: "IR",
    alpha3: "IRN",
    name: "Iran",
    nationality: "Iranian, Persian",
  },
  {
    numeric: "368",
    alpha2: "IQ",
    alpha3: "IRQ",
    name: "Iraq",
    nationality: "Iraqi",
  },
  {
    numeric: "372",
    alpha2: "IE",
    alpha3: "IRL",
    name: "Ireland",
    nationality: "Irish",
  },
  {
    numeric: "833",
    alpha2: "IM",
    alpha3: "IMN",
    name: "Isle of Man",
    nationality: "Manx",
  },
  {
    numeric: "376",
    alpha2: "IL",
    alpha3: "ISR",
    name: "Israel",
    nationality: "Israeli",
  },
  {
    numeric: "380",
    alpha2: "IT",
    alpha3: "ITA",
    name: "Italy",
    nationality: "Italian",
  },
  {
    numeric: "388",
    alpha2: "JM",
    alpha3: "JAM",
    name: "Jamaica",
    nationality: "Jamaican",
  },
  {
    numeric: "392",
    alpha2: "JP",
    alpha3: "JPN",
    name: "Japan",
    nationality: "Japanese",
  },
  {
    numeric: "832",
    alpha2: "JE",
    alpha3: "JEY",
    name: "Jersey",
    nationality: "Channel Island",
  },
  {
    numeric: "400",
    alpha2: "JO",
    alpha3: "JOR",
    name: "Jordan",
    nationality: "Jordanian",
  },
  {
    numeric: "398",
    alpha2: "KZ",
    alpha3: "KAZ",
    name: "Kazakhstan",
    nationality: "Kazakhstani, Kazakh",
  },
  {
    numeric: "404",
    alpha2: "KE",
    alpha3: "KEN",
    name: "Kenya",
    nationality: "Kenyan",
  },
  {
    numeric: "296",
    alpha2: "KI",
    alpha3: "KIR",
    name: "Kiribati",
    nationality: "I-Kiribati",
  },
  {
    numeric: "408",
    alpha2: "KP",
    alpha3: "PRK",
    name: "North Korea",
    nationality: "North Korean",
  },
  {
    numeric: "410",
    alpha2: "KR",
    alpha3: "KOR",
    name: "South Korea",
    nationality: "South Korean",
  },
  {
    numeric: "414",
    alpha2: "KW",
    alpha3: "KWT",
    name: "Kuwait",
    nationality: "Kuwaiti",
  },
  {
    numeric: "417",
    alpha2: "KG",
    alpha3: "KGZ",
    name: "Kyrgyzstan",
    nationality: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz",
  },
  {
    numeric: "418",
    alpha2: "LA",
    alpha3: "LAO",
    name: "Lao People's Democratic Republic",
    nationality: "Lao, Laotian",
  },
  {
    numeric: "428",
    alpha2: "LV",
    alpha3: "LVA",
    name: "Latvia",
    nationality: "Latvian",
  },
  {
    numeric: "422",
    alpha2: "LB",
    alpha3: "LBN",
    name: "Lebanon",
    nationality: "Lebanese",
  },
  {
    numeric: "426",
    alpha2: "LS",
    alpha3: "LSO",
    name: "Lesotho",
    nationality: "Basotho",
  },
  {
    numeric: "430",
    alpha2: "LR",
    alpha3: "LBR",
    name: "Liberia",
    nationality: "Liberian",
  },
  {
    numeric: "434",
    alpha2: "LY",
    alpha3: "LBY",
    name: "Libya",
    nationality: "Libyan",
  },
  {
    numeric: "438",
    alpha2: "LI",
    alpha3: "LIE",
    name: "Liechtenstein",
    nationality: "Liechtenstein",
  },
  {
    numeric: "440",
    alpha2: "LT",
    alpha3: "LTU",
    name: "Lithuania",
    nationality: "Lithuanian",
  },
  {
    numeric: "442",
    alpha2: "LU",
    alpha3: "LUX",
    name: "Luxembourg",
    nationality: "Luxembourg, Luxembourgish",
  },
  {
    numeric: "446",
    alpha2: "MO",
    alpha3: "MAC",
    name: "Macao",
    nationality: "Macanese, Chinese",
  },
  {
    numeric: "807",
    alpha2: "MK",
    alpha3: "MKD",
    name: "Macedonia (the former Yugoslav Republic of)",
    nationality: "Macedonian",
  },
  {
    numeric: "450",
    alpha2: "MG",
    alpha3: "MDG",
    name: "Madagascar",
    nationality: "Malagasy",
  },
  {
    numeric: "454",
    alpha2: "MW",
    alpha3: "MWI",
    name: "Malawi",
    nationality: "Malawian",
  },
  {
    numeric: "458",
    alpha2: "MY",
    alpha3: "MYS",
    name: "Malaysia",
    nationality: "Malaysian",
  },
  {
    numeric: "462",
    alpha2: "MV",
    alpha3: "MDV",
    name: "Maldives",
    nationality: "Maldivian",
  },
  {
    numeric: "466",
    alpha2: "ML",
    alpha3: "MLI",
    name: "Mali",
    nationality: "Malian, Malinese",
  },
  {
    numeric: "470",
    alpha2: "MT",
    alpha3: "MLT",
    name: "Malta",
    nationality: "Maltese",
  },
  {
    numeric: "584",
    alpha2: "MH",
    alpha3: "MHL",
    name: "Marshall Islands",
    nationality: "Marshallese",
  },
  {
    numeric: "474",
    alpha2: "MQ",
    alpha3: "MTQ",
    name: "Martinique",
    nationality: "Martiniquais, Martinican",
  },
  {
    numeric: "478",
    alpha2: "MR",
    alpha3: "MRT",
    name: "Mauritania",
    nationality: "Mauritanian",
  },
  {
    numeric: "480",
    alpha2: "MU",
    alpha3: "MUS",
    name: "Mauritius",
    nationality: "Mauritian",
  },
  {
    numeric: "175",
    alpha2: "YT",
    alpha3: "MYT",
    name: "Mayotte",
    nationality: "Mahoran",
  },
  {
    numeric: "484",
    alpha2: "MX",
    alpha3: "MEX",
    name: "Mexico",
    nationality: "Mexican",
  },
  {
    numeric: "583",
    alpha2: "FM",
    alpha3: "FSM",
    name: "Micronesia (Federated States of)",
    nationality: "Micronesian",
  },
  {
    numeric: "498",
    alpha2: "MD",
    alpha3: "MDA",
    name: "Moldova (Republic of)",
    nationality: "Moldovan",
  },
  {
    numeric: "492",
    alpha2: "MC",
    alpha3: "MCO",
    name: "Monaco",
    nationality: "Monégasque, Monacan",
  },
  {
    numeric: "496",
    alpha2: "MN",
    alpha3: "MNG",
    name: "Mongolia",
    nationality: "Mongolian",
  },
  {
    numeric: "499",
    alpha2: "ME",
    alpha3: "MNE",
    name: "Montenegro",
    nationality: "Montenegrin",
  },
  {
    numeric: "500",
    alpha2: "MS",
    alpha3: "MSR",
    name: "Montserrat",
    nationality: "Montserratian",
  },
  {
    numeric: "504",
    alpha2: "MA",
    alpha3: "MAR",
    name: "Morocco",
    nationality: "Moroccan",
  },
  {
    numeric: "508",
    alpha2: "MZ",
    alpha3: "MOZ",
    name: "Mozambique",
    nationality: "Mozambican",
  },
  {
    numeric: "104",
    alpha2: "MM",
    alpha3: "MMR",
    name: "Myanmar",
    nationality: "Burmese",
  },
  {
    numeric: "516",
    alpha2: "NA",
    alpha3: "NAM",
    name: "Namibia",
    nationality: "Namibian",
  },
  {
    numeric: "520",
    alpha2: "NR",
    alpha3: "NRU",
    name: "Nauru",
    nationality: "Nauruan",
  },
  {
    numeric: "524",
    alpha2: "NP",
    alpha3: "NPL",
    name: "Nepal",
    nationality: "Nepali, Nepalese",
  },
  {
    numeric: "528",
    alpha2: "NL",
    alpha3: "NLD",
    name: "Netherlands",
    nationality: "Dutch, Netherlandic",
  },
  {
    numeric: "540",
    alpha2: "NC",
    alpha3: "NCL",
    name: "New Caledonia",
    nationality: "New Caledonian",
  },
  {
    numeric: "554",
    alpha2: "NZ",
    alpha3: "NZL",
    name: "New Zealand",
    nationality: "New Zealand, NZ",
  },
  {
    numeric: "558",
    alpha2: "NI",
    alpha3: "NIC",
    name: "Nicaragua",
    nationality: "Nicaraguan",
  },
  {
    numeric: "562",
    alpha2: "NE",
    alpha3: "NER",
    name: "Niger",
    nationality: "Nigerien",
  },
  {
    numeric: "566",
    alpha2: "NG",
    alpha3: "NGA",
    name: "Nigeria",
    nationality: "Nigerian",
  },
  {
    numeric: "570",
    alpha2: "NU",
    alpha3: "NIU",
    name: "Niue",
    nationality: "Niuean",
  },
  {
    numeric: "574",
    alpha2: "NF",
    alpha3: "NFK",
    name: "Norfolk Island",
    nationality: "Norfolk Island",
  },
  {
    numeric: "580",
    alpha2: "MP",
    alpha3: "MNP",
    name: "Northern Mariana Islands",
    nationality: "Northern Marianan",
  },
  {
    numeric: "578",
    alpha2: "NO",
    alpha3: "NOR",
    name: "Norway",
    nationality: "Norwegian",
  },
  {
    numeric: "512",
    alpha2: "OM",
    alpha3: "OMN",
    name: "Oman",
    nationality: "Omani",
  },
  {
    numeric: "586",
    alpha2: "PK",
    alpha3: "PAK",
    name: "Pakistan",
    nationality: "Pakistani",
  },
  {
    numeric: "585",
    alpha2: "PW",
    alpha3: "PLW",
    name: "Palau",
    nationality: "Palauan",
  },
  {
    numeric: "275",
    alpha2: "PS",
    alpha3: "PSE",
    name: "Palestine, State of",
    nationality: "Palestinian",
  },
  {
    numeric: "591",
    alpha2: "PA",
    alpha3: "PAN",
    name: "Panama",
    nationality: "Panamanian",
  },
  {
    numeric: "598",
    alpha2: "PG",
    alpha3: "PNG",
    name: "Papua New Guinea",
    nationality: "Papua New Guinean, Papuan",
  },
  {
    numeric: "600",
    alpha2: "PY",
    alpha3: "PRY",
    name: "Paraguay",
    nationality: "Paraguayan",
  },
  {
    numeric: "604",
    alpha2: "PE",
    alpha3: "PER",
    name: "Peru",
    nationality: "Peruvian",
  },
  {
    numeric: "608",
    alpha2: "PH",
    alpha3: "PHL",
    name: "Philippines",
    nationality: "Philippine, Filipino",
  },
  {
    numeric: "612",
    alpha2: "PN",
    alpha3: "PCN",
    name: "Pitcairn",
    nationality: "Pitcairn Island",
  },
  {
    numeric: "616",
    alpha2: "PL",
    alpha3: "POL",
    name: "Poland",
    nationality: "Polish",
  },
  {
    numeric: "620",
    alpha2: "PT",
    alpha3: "PRT",
    name: "Portugal",
    nationality: "Portuguese",
  },
  {
    numeric: "630",
    alpha2: "PR",
    alpha3: "PRI",
    name: "Puerto Rico",
    nationality: "Puerto Rican",
  },
  {
    numeric: "634",
    alpha2: "QA",
    alpha3: "QAT",
    name: "Qatar",
    nationality: "Qatari",
  },
  {
    numeric: "638",
    alpha2: "RE",
    alpha3: "REU",
    name: "Réunion",
    nationality: "Réunionese, Réunionnais",
  },
  {
    numeric: "642",
    alpha2: "RO",
    alpha3: "ROU",
    name: "Romania",
    nationality: "Romanian",
  },
  {
    numeric: "643",
    alpha2: "RU",
    alpha3: "RUS",
    name: "Russian Federation",
    nationality: "Russian",
  },
  {
    numeric: "646",
    alpha2: "RW",
    alpha3: "RWA",
    name: "Rwanda",
    nationality: "Rwandan",
  },
  {
    numeric: "652",
    alpha2: "BL",
    alpha3: "BLM",
    name: "Saint Barthélemy",
    nationality: "Barthélemois",
  },
  {
    numeric: "654",
    alpha2: "SH",
    alpha3: "SHN",
    name: "Saint Helena, Ascension and Tristan da Cunha",
    nationality: "Saint Helenian",
  },
  {
    numeric: "659",
    alpha2: "KN",
    alpha3: "KNA",
    name: "Saint Kitts and Nevis",
    nationality: "Kittitian or Nevisian",
  },
  {
    numeric: "662",
    alpha2: "LC",
    alpha3: "LCA",
    name: "Saint Lucia",
    nationality: "Saint Lucian",
  },
  {
    numeric: "663",
    alpha2: "MF",
    alpha3: "MAF",
    name: "Saint Martin (French part)",
    nationality: "Saint-Martinoise",
  },
  {
    numeric: "666",
    alpha2: "PM",
    alpha3: "SPM",
    name: "Saint Pierre and Miquelon",
    nationality: "Saint-Pierrais or Miquelonnais",
  },
  {
    numeric: "670",
    alpha2: "VC",
    alpha3: "VCT",
    name: "Saint Vincent and the Grenadines",
    nationality: "Saint Vincentian, Vincentian",
  },
  {
    numeric: "882",
    alpha2: "WS",
    alpha3: "WSM",
    name: "Samoa",
    nationality: "Samoan",
  },
  {
    numeric: "674",
    alpha2: "SM",
    alpha3: "SMR",
    name: "San Marino",
    nationality: "Sammarinese",
  },
  {
    numeric: "678",
    alpha2: "ST",
    alpha3: "STP",
    name: "Sao Tome and Principe",
    nationality: "São Toméan",
  },
  {
    numeric: "682",
    alpha2: "SA",
    alpha3: "SAU",
    name: "Saudi Arabia",
    nationality: "Saudi, Saudi Arabian",
  },
  {
    numeric: "686",
    alpha2: "SN",
    alpha3: "SEN",
    name: "Senegal",
    nationality: "Senegalese",
  },
  {
    numeric: "688",
    alpha2: "RS",
    alpha3: "SRB",
    name: "Serbia",
    nationality: "Serbian",
  },
  {
    numeric: "690",
    alpha2: "SC",
    alpha3: "SYC",
    name: "Seychelles",
    nationality: "Seychellois",
  },
  {
    numeric: "694",
    alpha2: "SL",
    alpha3: "SLE",
    name: "Sierra Leone",
    nationality: "Sierra Leonean",
  },
  {
    numeric: "702",
    alpha2: "SG",
    alpha3: "SGP",
    name: "Singapore",
    nationality: "Singaporean",
  },
  {
    numeric: "534",
    alpha2: "SX",
    alpha3: "SXM",
    name: "Sint Maarten (Dutch part)",
    nationality: "Sint Maarten",
  },
  {
    numeric: "703",
    alpha2: "SK",
    alpha3: "SVK",
    name: "Slovakia",
    nationality: "Slovak",
  },
  {
    numeric: "705",
    alpha2: "SI",
    alpha3: "SVN",
    name: "Slovenia",
    nationality: "Slovenian, Slovene",
  },
  {
    numeric: "90",
    alpha2: "SB",
    alpha3: "SLB",
    name: "Solomon Islands",
    nationality: "Solomon Island",
  },
  {
    numeric: "706",
    alpha2: "SO",
    alpha3: "SOM",
    name: "Somalia",
    nationality: "Somali, Somalian",
  },
  {
    numeric: "710",
    alpha2: "ZA",
    alpha3: "ZAF",
    name: "South Africa",
    nationality: "South African",
  },
  {
    numeric: "239",
    alpha2: "GS",
    alpha3: "SGS",
    name: "South Georgia and the South Sandwich Islands",
    nationality: "South Georgia or South Sandwich Islands",
  },
  {
    numeric: "728",
    alpha2: "SS",
    alpha3: "SSD",
    name: "South Sudan",
    nationality: "South Sudanese",
  },
  {
    numeric: "724",
    alpha2: "ES",
    alpha3: "ESP",
    name: "Spain",
    nationality: "Spanish",
  },
  {
    numeric: "144",
    alpha2: "LK",
    alpha3: "LKA",
    name: "Sri Lanka",
    nationality: "Sri Lankan",
  },
  {
    numeric: "729",
    alpha2: "SD",
    alpha3: "SDN",
    name: "Sudan",
    nationality: "Sudanese",
  },
  {
    numeric: "740",
    alpha2: "SR",
    alpha3: "SUR",
    name: "Suriname",
    nationality: "Surinamese",
  },
  {
    numeric: "744",
    alpha2: "SJ",
    alpha3: "SJM",
    name: "Svalbard and Jan Mayen",
    nationality: "Svalbard",
  },
  {
    numeric: "748",
    alpha2: "SZ",
    alpha3: "SWZ",
    name: "Swaziland",
    nationality: "Swazi",
  },
  {
    numeric: "752",
    alpha2: "SE",
    alpha3: "SWE",
    name: "Sweden",
    nationality: "Swedish",
  },
  {
    numeric: "756",
    alpha2: "CH",
    alpha3: "CHE",
    name: "Switzerland",
    nationality: "Swiss",
  },
  {
    numeric: "760",
    alpha2: "SY",
    alpha3: "SYR",
    name: "Syria",
    nationality: "Syrian",
  },
  {
    numeric: "158",
    alpha2: "TW",
    alpha3: "TWN",
    name: "Taiwan, Province of China",
    nationality: "Chinese, Taiwanese",
  },
  {
    numeric: "762",
    alpha2: "TJ",
    alpha3: "TJK",
    name: "Tajikistan",
    nationality: "Tajikistani",
  },
  {
    numeric: "834",
    alpha2: "TZ",
    alpha3: "TZA",
    name: "Tanzania, United Republic of",
    nationality: "Tanzanian",
  },
  {
    numeric: "764",
    alpha2: "TH",
    alpha3: "THA",
    name: "Thailand",
    nationality: "Thai",
  },
  {
    numeric: "626",
    alpha2: "TL",
    alpha3: "TLS",
    name: "Timor-Leste",
    nationality: "Timorese",
  },
  {
    numeric: "768",
    alpha2: "TG",
    alpha3: "TGO",
    name: "Togo",
    nationality: "Togolese",
  },
  {
    numeric: "772",
    alpha2: "TK",
    alpha3: "TKL",
    name: "Tokelau",
    nationality: "Tokelauan",
  },
  {
    numeric: "776",
    alpha2: "TO",
    alpha3: "TON",
    name: "Tonga",
    nationality: "Tongan",
  },
  {
    numeric: "780",
    alpha2: "TT",
    alpha3: "TTO",
    name: "Trinidad and Tobago",
    nationality: "Trinidadian or Tobagonian",
  },
  {
    numeric: "788",
    alpha2: "TN",
    alpha3: "TUN",
    name: "Tunisia",
    nationality: "Tunisian",
  },
  {
    numeric: "792",
    alpha2: "TR",
    alpha3: "TUR",
    name: "Turkey",
    nationality: "Turkish",
  },
  {
    numeric: "795",
    alpha2: "TM",
    alpha3: "TKM",
    name: "Turkmenistan",
    nationality: "Turkmen",
  },
  {
    numeric: "796",
    alpha2: "TC",
    alpha3: "TCA",
    name: "Turks and Caicos Islands",
    nationality: "Turks and Caicos Island",
  },
  {
    numeric: "798",
    alpha2: "TV",
    alpha3: "TUV",
    name: "Tuvalu",
    nationality: "Tuvaluan",
  },
  {
    numeric: "800",
    alpha2: "UG",
    alpha3: "UGA",
    name: "Uganda",
    nationality: "Ugandan",
  },
  {
    numeric: "804",
    alpha2: "UA",
    alpha3: "UKR",
    name: "Ukraine",
    nationality: "Ukrainian",
  },
  {
    numeric: "784",
    alpha2: "AE",
    alpha3: "ARE",
    name: "United Arab Emirates",
    nationality: "Emirati, Emirian, Emiri",
  },
  {
    numeric: "826",
    alpha2: "GB",
    alpha3: "GBR",
    name: "United Kingdom",
    nationality: "British, UK",
  },
  {
    numeric: "581",
    alpha2: "UM",
    alpha3: "UMI",
    name: "United States Minor Outlying Islands",
    nationality: "American",
  },
  {
    numeric: "840",
    alpha2: "US",
    alpha3: "USA",
    name: "United States of America",
    nationality: "American",
  },
  {
    numeric: "858",
    alpha2: "UY",
    alpha3: "URY",
    name: "Uruguay",
    nationality: "Uruguayan",
  },
  {
    numeric: "860",
    alpha2: "UZ",
    alpha3: "UZB",
    name: "Uzbekistan",
    nationality: "Uzbekistani, Uzbek",
  },
  {
    numeric: "548",
    alpha2: "VU",
    alpha3: "VUT",
    name: "Vanuatu",
    nationality: "Ni-Vanuatu, Vanuatuan",
  },
  {
    numeric: "862",
    alpha2: "VE",
    alpha3: "VEN",
    name: "Venezuela",
    nationality: "Venezuelan",
  },
  {
    numeric: "704",
    alpha2: "VN",
    alpha3: "VNM",
    name: "Vietnam",
    nationality: "Vietnamese",
  },
  {
    numeric: "92",
    alpha2: "VG",
    alpha3: "VGB",
    name: "Virgin Islands (British)",
    nationality: "British Virgin Island",
  },
  {
    numeric: "850",
    alpha2: "VI",
    alpha3: "VIR",
    name: "Virgin Islands (U.S.)",
    nationality: "U.S. Virgin Island",
  },
  {
    numeric: "876",
    alpha2: "WF",
    alpha3: "WLF",
    name: "Wallis and Futuna",
    nationality: "Wallis and Futuna, Wallisian or Futunan",
  },
  {
    numeric: "732",
    alpha2: "EH",
    alpha3: "ESH",
    name: "Western Sahara",
    nationality: "Sahrawi, Sahrawian, Sahraouian",
  },
  {
    numeric: "887",
    alpha2: "YE",
    alpha3: "YEM",
    name: "Yemen",
    nationality: "Yemeni",
  },
  {
    numeric: "894",
    alpha2: "ZM",
    alpha3: "ZMB",
    name: "Zambia",
    nationality: "Zambian",
  },
  {
    numeric: "716",
    alpha2: "ZW",
    alpha3: "ZWE",
    name: "Zimbabwe",
    nationality: "Zimbabwean",
  },
];

const blackList = new Set([
  "Afghanistan",
  "Bahamas",
  "Belarus",
  "Bolivia",
  "Burundi",
  "Cambodia",
  "Central African Republic",
  "Congo, The Democratic Republic",
  "Cuba",
  "Ecuador",
  "Egypt",
  "Gaza Strip",
  "Guinea-Bissau",
  "Haiti",
  "Iran",
  "Iraq",
  "Lao People's Democratic Republic",
  "Lebanon",
  "Liberia",
  "Libya",
  "Mali",
  "Myanmar",
  "Nicaragua",
  "North Korea",
  "Pakistan",
  "Somalia",
  "South Sudan",
  "Sudan",
  "Syria",
  "Trinidad and Tobago",
  "Venezuela",
  "West Bank",
  "Western Sahara",
  "Yemen",
]);

const countries = ISO3116CountryList.filter((item) => {
  return !blackList.has(item.name);
});

const countryList = countries.sort((a, b) => a.name.localeCompare(b.name));

const nationalityList = countries.sort((a, b) =>
  a.nationality.localeCompare(b.nationality)
);

const getCountryName = (countryCode) => {
  if (!countryCode) return;

  const result = ISO3116CountryList.find((row) => {
    if (row.alpha2 === countryCode || row.alpha3 === countryCode) return row;
  });

  return result.name;
};

export {
  countryList as countries,
  nationalityList as nationalities,
  getCountryName,
};
