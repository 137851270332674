import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "api";

const initialState = {
  data: [],
  initiatePaymentData: {},
  loading: true,
  error: false,
  errorMessage: undefined,
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    setInitiatePaymentData(state, action) {
      state.initiatePaymentData = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const initiatePayment = createAsyncThunk(
  "initiatePayment",
  async (params) => {
    const { data } = await api.post("/ziyl/initiatepaymentfinxp", params);
    return data;
  }
);

export const sendSmsPaymentVerification = createAsyncThunk(
  "sendSmsPaymentVerification",
  async (params) => {
    const { data } = await api.post("/ziyl/otprequestfinxp", params);
    return data;
  }
);

export const processPayment = createAsyncThunk(
  "processPayment",
  async (params) => {
    const { data } = await api.post("/ziyl/processpaymentfinxp", params);
    return data;
  }
);

export const { setInitiatePaymentData } = paymentSlice.actions;

export default paymentSlice.reducer;
