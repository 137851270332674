import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DisplayAlert } from "Components/Alert/Alert"
import OneColumnLayout from "layout/OneColumnLayout";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Formik, Form } from "formik";
import { forgotPassword } from "redux/auth/authSlice";
import FormikController from "Components/Formik/FormikController";
import ProfileIcon from "assets/icons/Profile";
import EmailIcon from "assets/icons/Email";
import Button from "Components/Button";
import FixedBottomAction from "Components/FixedBottomAction";
import { appRoutes } from "utils/helpers";
import { useRequestForgotPasswordMutation } from "redux/auth/authSliceV2";
import styles from "./ForgottenPassword.module.css";

import * as Yup from "yup";

const initialValues = {
  email: "",
};

export function ForgottenPassword() {
  const navigate = useNavigate();
  const [isRequesLoading, setIsRequesLoading] = useState(false);

  const [
    requestForgotPasswordMutation,
    {
      isSuccess,
      data,
    }
  ] = useRequestForgotPasswordMutation();

  const onSubmit = async (requestingEmail) => {
    setIsRequesLoading(true);
    const { email } = requestingEmail;
    requestForgotPasswordMutation({requestingEmail:email});
  }

  const checkIfSuccess = () => {
    if (isSuccess && data.code === 200) {
      setIsRequesLoading(false);
        if(data.message == "Please wait before retrying.") {
          DisplayAlert({
            title: "",
            icon: "info",
            text: data.message,
            confirmHandler: navigate(appRoutes.signin)
          });
        }
        else {
          DisplayAlert({
            title: "Success",
            icon: "success",
            text: data.message,
            confirmHandler: navigate(appRoutes.signin)
          });
        }
    } else if ( isSuccess && data.status === "failed") {
      DisplayAlert({
        title: "",
        icon: "info",
        text: data.message,
        confirmHandler: navigate(appRoutes.signin)
      });
    }
  }

  useEffect(() => {
    checkIfSuccess();
  }, [isSuccess, data]);

  const validationSchema = Yup.object({
    email: Yup
      .string()
      .email("Not a valid email.")
      .required("Email is a required field*"),
  });

  return (
    <OneColumnLayout>
      <Box px={1.5} pt={5}>
        <Paper
          sx={{ borderRadius: "18px", maxWidth: "684px", margin: "0 auto" }}
        >
          <div className={styles.titleContainer}>
            <h3 className={styles.title}>
              Forgot Password
            </h3>

          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            >
            {(formik) => (
                <Form autoComplete="off">
                <Grid container>
                  <Grid item xs={12} px={1.5} sx={{ marginY: "15px" }}>
                    <FormikController
                      control="input"
                      type="email"
                      label="Email"
                      name="email"
                      icon={<EmailIcon />}
                    />
                  </Grid>

                  <FixedBottomAction
                    style={{
                      borderBottomLeftRadius: 24,
                      borderBottomRightRadius: 24,
                    }}
                  >
                    <Button
                      type="submit"
                      leftIcon={<ProfileIcon />}
                      color="light-pink"
                      loading={isRequesLoading}
                      disabled={isRequesLoading}
                    >
                    {isRequesLoading ? "Loading..." : "Confirm"}
                    </Button>
                    <Button
                      color="light-pink"
                      type="button"
                      onClick={() => navigate(appRoutes.signin)}
                    >
                      Back
                    </Button>
                  </FixedBottomAction>
                </Grid>
              </Form>
            )}
          </Formik>
        </Paper>
      </Box>
    </OneColumnLayout>
  );
}
