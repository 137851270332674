import { ErrorMessage, Field, useField } from "formik";
import { IconButton, InputAdornment, TextField } from "@mui/material";

import EyeClosedIcon from "assets/icons/EyeClosed";
import EyeIcon from "assets/icons/Eye";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import TextError from "./TextError";
import { useState } from "react";
import { useStyles } from "./styles";

const Input = (props) => {
  const { name, label, type, icon, ...rest } = props;
  const classes = useStyles();

  const [field] = useField(name);
  const [showPassword, handleShowPassword] = useState(false);

  return (
    <Grid container>
      <Field
        {...field}
        {...rest}
        as={TextField}
        label={icon ? "" : label}
        placeholder={icon ? label : ""}
        type={showPassword && type === "password" ? "text" : type}
        variant="standard"
        fullWidth
        InputProps={{
          startAdornment: (
            <>
              {icon ? (
                <InputAdornment
                  position="start"
                  classes={{ root: classes.iconRootStart }}
                >
                  {typeof icon === "string" ? <img src={icon} /> : icon}
                </InputAdornment>
              ) : null}
            </>
          ),
          endAdornment: (
            <>
              {type === "password" ? (
                <InputAdornment
                  position="end"
                  classes={{ root: classes.iconRootEnd }}
                >
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleShowPassword(!showPassword)}
                    classes={{ root: classes.endIcon }}
                  >
                    {showPassword ? (
                      <EyeIcon color="blue" size={18} />
                    ) : (
                      <EyeClosedIcon color="blue" size={18} />
                    )}
                  </IconButton>
                </InputAdornment>
              ) : null}
            </>
          ),
          classes: {
            root: classes.textFieldRoot,
            notchedOutline: classes.textFieldOutline,
            input: classes.input,
          },
        }}
      />
      <ErrorMessage name={name} component={TextError} />
    </Grid>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
};

export default Input;
