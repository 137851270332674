import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "api";

const initialState = {
  data: {},
  registerBusinessData: {
    // will set this data as static values for now until further notice
    iban4u_payment_account: {
      purpose_of_account_opening: "string",
      partners_incoming_transactions: "string",
      partners_outgoing_transactions: "string",
      country_origin: ["ABC", "GBR"],
      country_remittance: ["MLT", "PHP"],
      estimated_monthly_transactions: 1000,
      average_amount_transaction_euro: 1000,
      accepting_third_party_funds: "YES",
    },
    sepa_dd_direct_debit: {
      currently_processing_sepa_dd: "YES",
      details_of_product_services: "this is description",
      sepa_dd_value: 100,
      sepa_dd_volume_per_month: 200,
    },
    credit_card_processing: {
      currently_processing_cc_payments: "YES",
      "trading_urls<string>": "url1, url2, url3",
      offer_recurring_billing: "YES",
      recurring_details: "string",
      offer_refunds: "YES",
      refund_details: "string",
      country: "MLT",
      distribution_sale_volume: 20,
      processing_account_primary_currency: "EUR",
      average_ticket_amount: 300,
      highest_ticket_amount: 1000,
      alternative_payment_methods: ["GIRO", "SOFORT"],
      other_alternative_payment_method: "string",
      method_currently_offered: ["GIRO", "SKRILL"],
      other_alternative_payment_method_used: "string",
      current_mcc: "string",
      current_descriptor: "string",
      cb_volumes_twelve_months: 12,
      cc_volumes_twelve_months: 12,
      refund_volumes_twelve_months: 12,
      current_acquire_psp: "string",
    },
    naturalPersonsIds: [],
    nonNaturalPersonsIds: [],
  },
  sendToMobileSuccess: false,
  sendToEmailSuccess: false,
  loading: true,
  error: false,
  errorMessage: undefined,
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setRegistrationData(state, action) {
      state.data = { ...state.data, ...action.payload };
    },
    setRegistrationBusinessData(state, action) {
      state.registerBusinessData = {
        ...state.registerBusinessData,
        ...action.payload,
      };
    },
    clearRegistrationForm(state, action) {
      state.registerBusinessData = initialState.registerBusinessData;
    },
  },
  extraReducers: (builder) => {
    // login credentials
    builder.addCase(setLoginCredentials.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(setLoginCredentials.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
    // send sms verifications
    builder.addCase(sendSMSVerification.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(sendSMSVerification.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
    // get subsub verification token
    builder.addCase(getSumsubVerificationCode.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getSumsubVerificationCode.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
    // send subsub sms to mobile
    builder.addCase(sendSubsubToMobile.fulfilled, (state) => {
      state.loading = true;
      state.sendToMobileSuccess = true;
    });
    builder.addCase(sendSubsubToMobile.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
    // send sumsub to email
    builder.addCase(sendSubsubToEmail.fulfilled, (state) => {
      state.error = true;
      state.sendToEmailSuccess = true;
    });
    builder.addCase(sendSubsubToEmail.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
  },
});

export const setLoginCredentials = createAsyncThunk(
  "loginCredentials",
  async (params) => {
    const { data } = await api.post("/ziyl/sendactivationemailfinxp", params, {
      responseType: "text",
    });
    return data;
  }
);

export const sendSMSVerification = createAsyncThunk(
  "sendSMSVerification",
  async (params) => {
    const { data } = await api.post("/ziyl/getregistrationotpfinxp", params);
    return data;
  }
);

export const getSumsubVerificationCode = createAsyncThunk(
  "getSumsubVerificationCode",
  async (params) => {
    const { data } = await api.post("/ziyl/registrationfinxpv3", params);
    return data;
  }
);

export const sendSubsubToMobile = createAsyncThunk(
  "sendSubsubToMobile",
  async (params) => {
    const { data } = await api.post("/ziyl/sendsmszazoo", params);
    return data;
  }
);

export const sendSubsubToEmail = createAsyncThunk(
  "sendSubsubToEmail",
  async (params) => {
    const { data } = await api.post("/ziyl/sendemailzazoo", params, {
      responseType: "text",
    });
    return data;
  }
);

export const getRegistrationStatus = createAsyncThunk(
  "getRegistrationStatus",
  async (params) => {
    const { data } = await api.post(
      "/ziyl/getregistrationstatusfinxp",
      params
    );
    return data;
  }
);

export const registerBusiness = createAsyncThunk(
  "registerBusiness",
  async (params) => {
    const { data } = await api.post("/ziyl/registrationfbusinessfinxp", params);
    return data;
  }
);

export const registerBusinessV2 = createAsyncThunk(
  "registerBusinessV2",
  async (params) => {
    const { data } = await api.post(
      "/ziyl/registrationfbusinessV2finxp",
      params
    );
    return data;
  }
);

export const registerBusinessCreateNaturalPerson = createAsyncThunk(
  "registerBusinessCreateNaturalPerson",
  async (params) => {
    const { data } = await api.post("/ziyl/createBusinessPersonFinXP", params);
    return data;
  }
);

export const registerBusinessCreateNonNaturalPerson = createAsyncThunk(
  "registerBusinessNonNaturalPerson",
  async (params) => {
    const { data } = await api.post(
      "/ziyl/createnonnaturalpersonfinxp",
      params
    );
    return data;
  }
);

export const registerBusinessComposition = createAsyncThunk(
  "registerBusinessComposition",
  async (params) => {
    const { data } = await api.post("/ziyl/businessCompositionFinXP", params);
    return data;
  }
);

export const {
  setRegistrationData,
  setRegistrationBusinessData,
  clearRegistrationForm,
} = registrationSlice.actions;

export default registrationSlice.reducer;
