import { ErrorMessage, Field, useField } from "formik";
import { FormLabel, Grid, TextField } from "@mui/material";

import PropTypes from "prop-types";
import TextError from "./TextError";
import { useTextAreaStyles } from "./styles";

const EmptyTextarea = (props) => {
  const { minRows, ...rest } = props;
  const classes = useTextAreaStyles();
  return (
    <TextField
      {...rest}
      multiline
      minRows={minRows ? minRows : 5}
      InputProps={{
        classes: {
          root: classes.textFieldRoot,
          notchedOutline: classes.textFieldOutline,
          input: classes.input,
        },
      }}
    />
  );
};

EmptyTextarea.propTypes = {
  name: PropTypes.string,
  minRows: PropTypes.number,
};

const TextArea = ({ label, name, ...rest }) => {
  const [field] = useField(name);
  return (
    <Grid container direction="column" gap={1}>
      <FormLabel component="legend">{label}</FormLabel>
      <Field {...field} as={EmptyTextarea} {...rest} />
      <ErrorMessage name={name} component={TextError} />
    </Grid>
  );
};

TextArea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

export default TextArea;
