import PropTypes from "prop-types";

const TextError = ({ children }) => {
  return (
    <>
      <div style={{ color: "red", fontSize: "12px" }}>{children}</div>
    </>
  );
};

TextError.propTypes = {
  children: PropTypes.node,
};

export default TextError;
