import { useSelector } from "react-redux"
import { Navigate, useLocation } from "react-router-dom"
import { appRoutes } from "utils/helpers"

export function PrivateRoute({ children, redirectTo = appRoutes.signin, ...props }) {
  const auth = useSelector((state) => state.auth.data)
  const location = useLocation()

  if (!auth?.access_token) {
    return <Navigate to={redirectTo} state={{ from: location }} replace />
  }
  return children
}
