const LIGHTBLUE = "#DDEBFF";
const ORANGE = "#FBB445";
const GREEN = "#0DCA9D";
const RED = "#FF7171";
const LIGHTGREY = "#F5F4F4";
const LIGHTPINK = "#FFF0F9";
const PINK = "#E7038E";
const MEDIUMGREY = "#B4B7BC";
const ACCENTBLUE = "#086AFB";
const SOFTPINK = "#FFC9E9";

const colors = new Map();
colors.set("light-blue", LIGHTBLUE);
colors.set("orange", ORANGE);
colors.set("green", GREEN);
colors.set("red", RED);
colors.set("light-grey", LIGHTGREY);
colors.set("light-pink", LIGHTPINK);
colors.set("pink", PINK);
colors.set("medium-grey", MEDIUMGREY);
colors.set("blue", ACCENTBLUE);
colors.set("soft-pink", SOFTPINK);

export function getColor(colorName) {
  if (colors.has(colorName)) {
    return colors.get(colorName);
  } else {
    return;
  }
}
