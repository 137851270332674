import React, { Suspense, Fragment } from "react";
import { CircularProgress } from "@material-ui/core";

import useStyles from "./styles";

const Loadable = (Component) => {
  return function LazyLoadWrapper(props) {
    const classes = useStyles();

    return (
      <Suspense
        fallback={
          <Fragment>
            <div className={classes.loaderContainer}>
              <CircularProgress />
            </div>
          </Fragment>
        }
      >
        <Component {...props} />
      </Suspense>
    );
  };
};

export default Loadable;
