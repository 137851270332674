import React, { useEffect, useState } from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";

import { appRoutes } from "utils/helpers";
import OneColumnLayout from "layout/OneColumnLayout";
import { isLoadingBackground, snackbarMessage } from "utils/globalStates";
import useStyles from "./styles";

const VerifyMail = () => {
  const params = new URLSearchParams(window.location.search);
  const codeParams = params.get("code");
  const phoneParams = params.get("mobile");
  const uuidParams = params.get("uuid");
  const mailParams = params.get("email");
  let navigate = useNavigate();

  const classes = useStyles();

  const [, setLoadableBackground] = useAtom(isLoadingBackground);
  const [, setSnackbarMessage] = useAtom(snackbarMessage);

  const [errStatus, setErrStatus] = useState({});

  function verifyEmail() {
    setLoadableBackground(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: mailParams,
      token: codeParams,
      uuid: uuidParams,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_APIURL}/ziyl/verifyemailfinxp`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result?.code === 200 && result?.message === "Verified") {
          navigate(appRoutes.registerPersonal, {
            state: {
              verifiedMailToken: true,
              mail: mailParams,
              phone: phoneParams,
            },
          });
        }
        if (result?.code && [400, 401, 404].includes(result?.code)) {
          setErrStatus({ ...result });
          setSnackbarMessage({
            open: true,
            message: `${result?.code}:${
              result?.message ? result?.message : "Something went wrong"
            }`,
            alertType: "error",
          });
        }
        setLoadableBackground(false);
      })
      .catch((error) => {
        console.error("error", error);
        setSnackbarMessage({
          open: true,
          message: "Error with data",
          alertType: "error",
        });
        setLoadableBackground(false);
      });
  }

  useEffect(() => {
    if (codeParams !== null) {
      verifyEmail();
    }
  }, [codeParams]);

  return (
    <OneColumnLayout>
      <Card classes={{ root: classes.cardContainer }} variant="outlined">
        <CardContent classes={{ root: classes.cardContent }}>
          {errStatus?.code && [400, 401, 404].includes(errStatus?.code) ? (
            <CancelRoundedIcon color="secondary" />
          ) : null}
          <Typography variant="h6" classes={{ root: classes.text }}>
            {errStatus?.code && [400, 401, 404].includes(errStatus?.code)
              ? errStatus?.message
              : ""}
          </Typography>
        </CardContent>
      </Card>
    </OneColumnLayout>
  );
};

export default VerifyMail;
