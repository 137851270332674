import { useDispatch /* useSelector */ } from "react-redux";

import style from "./avatar.module.css";
import { uploadProfileImage } from "redux/profile/profileSlice";
import { convertImageToBase64 } from "utils/helpers";
import { getProfile } from "redux/profile/profileSlice";
import DefaultAvatar from "assets/images/default-avatar.jpg";
import { useGetProfileQuery } from "redux/profile/profileSliceV2";

export function Avatar({
  src,
  alt,
  icon,
  iconActionHander,
  isBase64Image = false,
  shadow = false,
  size = "small",
  borderColor = "#ddebff",
  fileUpload = false,
  ...props
}) {
  const dispatch = useDispatch();

  const { data: profileDetailsData, refetch } = useGetProfileQuery();

  const handleUploadFile = async (e) => {
    const file = e.target?.files[0];
    const base64 = await convertImageToBase64(file);
    dispatch(
      uploadProfileImage({
        email: profileDetailsData?.email,
        file: base64,
      })
    ).then((payload) => {
      if (payload) {
        refetch();
      }
    });
  };

  const srcImage = src ? `data:image/jpeg;base64, ${src}` : DefaultAvatar;

  return (
    <div
      className={[style[size], icon ? style.hasIcon : null].join(" ")}
      {...props}
    >
      <img
        style={{ borderColor }}
        className={[style.image, shadow ? style.shadow : null].join(" ")}
        src={srcImage}
        alt={alt}
      />
      {icon && (
        <div onClick={iconActionHander} className={style.iconButton}>
          {fileUpload ? (
            <label htmlFor="avatar">
              <input
                accept=".jpg, .jpeg, .png"
                type="file"
                name="file"
                id="avatar"
                onChange={(e) => handleUploadFile(e)}
              />
              <span>{icon}</span>
            </label>
          ) : (
            <button type="button">{icon}</button>
          )}
        </div>
      )}
    </div>
  );
}
