import { getColor } from "../color";
export function Eye({ size = 14, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill={getColor(color)}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 3.27273C9.41182 3.27273 11.5627 4.62818 12.6127 6.77273C11.5627 8.91727 9.41182 10.2727 7 10.2727C4.58818 10.2727 2.43727 8.91727 1.38727 6.77273C2.43727 4.62818 4.58818 3.27273 7 3.27273ZM7 2C3.81818 2 1.10091 3.97909 0 6.77273C1.10091 9.56636 3.81818 11.5455 7 11.5455C10.1818 11.5455 12.8991 9.56636 14 6.77273C12.8991 3.97909 10.1818 2 7 2ZM7 5.18182C7.87818 5.18182 8.59091 5.89455 8.59091 6.77273C8.59091 7.65091 7.87818 8.36364 7 8.36364C6.12182 8.36364 5.40909 7.65091 5.40909 6.77273C5.40909 5.89455 6.12182 5.18182 7 5.18182ZM7 3.90909C5.42182 3.90909 4.13636 5.19455 4.13636 6.77273C4.13636 8.35091 5.42182 9.63636 7 9.63636C8.57818 9.63636 9.86364 8.35091 9.86364 6.77273C9.86364 5.19455 8.57818 3.90909 7 3.90909Z"
        fill={getColor(color)}
      />
    </svg>
  );
}
