import { getColor } from "../color";
export function Profile({ size = 14, color, opacity = 1 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill={getColor(color) || color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.775 7.7C5.775 8.183 5.383 8.575 4.9 8.575C4.417 8.575 4.025 8.183 4.025 7.7C4.025 7.217 4.417 6.825 4.9 6.825C5.383 6.825 5.775 7.217 5.775 7.7ZM9.1 6.825C8.617 6.825 8.225 7.217 8.225 7.7C8.225 8.183 8.617 8.575 9.1 8.575C9.583 8.575 9.975 8.183 9.975 7.7C9.975 7.217 9.583 6.825 9.1 6.825ZM14 7C14 10.864 10.864 14 7 14C3.136 14 0 10.864 0 7C0 3.136 3.136 0 7 0C10.864 0 14 3.136 14 7ZM6.062 1.484C7.042 3.108 8.82 4.2 10.85 4.2C11.172 4.2 11.487 4.165 11.788 4.116C10.808 2.492 9.03 1.4 7 1.4C6.678 1.4 6.363 1.435 6.062 1.484ZM1.694 5.229C2.891 4.55 3.815 3.444 4.256 2.121C3.059 2.8 2.135 3.906 1.694 5.229ZM12.6 7C12.6 6.454 12.516 5.929 12.369 5.432C11.879 5.537 11.375 5.6 10.85 5.6C8.659 5.6 6.706 4.592 5.418 3.017C4.683 4.809 3.22 6.216 1.4 6.902C1.407 6.93 1.4 6.965 1.4 7C1.4 10.087 3.913 12.6 7 12.6C10.087 12.6 12.6 10.087 12.6 7Z"
        fill={getColor(color) || color}
        fillOpacity="1"
      />
    </svg>
  );
}
