import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "api";

const initialState = {
  data: [],
  details: {
    curbal: null,
    avlbal: "0",
    displayedAvailableBalance: "0",
  },
  loading: true,
  error: false,
  errorMessage: undefined,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setInitialdisplayedAvailableBalance(state, action) {
      const parsedAvailableBalance =
        (action.payload?.availableBalance &&
          parseFloat(action.payload?.availableBalance)) ||
        0;

      if (parsedAvailableBalance) {
        state.details.displayedAvailableBalance =
          parsedAvailableBalance.toFixed(2);
      }
    },
    handleChangeAccountCurrentBalance(state, action) {
      const parsedAvailableBalance =
        (state.details.avlbal && parseFloat(state.details.avlbal)) || 0;
      const amountToSend = parseFloat(action.payload.amountTosend);
      const displayedAvailableBalance =
        (state.details?.avlbal && parseFloat(state.details?.avlbal)) || 0;
      let newCurrentBalance = displayedAvailableBalance;
      if (
        amountToSend &&
        amountToSend > 0 &&
        !Number.isNaN(amountToSend) &&
        !Number.isNaN(displayedAvailableBalance) &&
        !(amountToSend > displayedAvailableBalance)
      ) {
        newCurrentBalance = displayedAvailableBalance - amountToSend;
      } else {
        newCurrentBalance = parsedAvailableBalance;
      }
      state.details.displayedAvailableBalance = newCurrentBalance.toFixed(2);
    },
  },
  extraReducers: (builder) => {
    // get accounts
    builder.addCase(getAccounts.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getAccounts.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
    // get account details
    builder.addCase(getAccountDetails.fulfilled, (state, action) => {
      const data = action.payload?.data || {};
      if (Object.keys(data).length > 0) {
        Object.assign(data, {
          ...data,
          displayedAvailableBalance: data?.avlbal,
        });
      }
      state.details = data;
      state.loading = false;
    });
    builder.addCase(getAccountDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const getAccounts = createAsyncThunk("getAccounts", async () => {
  const { data } = await api.get("/ziyl/accountsfinxp");
  return data;
});

export const getAccountDetails = createAsyncThunk(
  "getAccountDetails",
  async (accountId) => {
    const { data } = await api.get(
      `/ziyl/accountdetailsfinxp/${accountId}`
    );
    return data;
  }
);

export const {
  handleChangeAccountCurrentBalance,
  setInitialdisplayedAvailableBalance,
  setNewCurrentBalance,
} = accountSlice.actions;

export default accountSlice.reducer;
