import * as React from "react";

import { Container, Typography } from "@mui/material";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export default function CardWithNavBar() {
  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container
        sx={{
          height: "90%",
          width: "90%",
          backgroundColor: "white",
          borderRadius: "24px",
          padding: "20px",
        }}
      >
        <Typography variant="h4" component="div">
          Title
        </Typography>
        <Grid container sx={{height: "95%"}}>
          <Grid item xs={3} sx={{ backgroundColor: "red" }}>
            xs=4
          </Grid>
          <Grid item xs={9} sx={{ backgroundColor: "green" }}>
            xs=4
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
