import { Fragment } from "react";
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useAtom } from "jotai";

import { snackbarMessage } from "utils/globalStates";

const SnackbarAlert = () => {
  const [snackbarAlertMessage, setSnackbarMessage] = useAtom(snackbarMessage);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarMessage({
      open: false,
      message: "",
      alertType: "",
    });
  };

  return (
    <Fragment>
      {snackbarAlertMessage.open ? (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={snackbarAlertMessage.open}
          autoHideDuration={5000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={snackbarAlertMessage.alertType}
          >
            {snackbarAlertMessage.message}
          </Alert>
        </Snackbar>
      ) : null}
    </Fragment>
  );
};

export default SnackbarAlert;
