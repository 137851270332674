import { useDispatch } from "react-redux";
import { useEffect } from "react";

import { signout } from "redux/auth/authSlice";

export default function IdleSessionLogout({ children }) {
  const lastActiveTime = localStorage.getItem("lastActiveTime");

  const dispatch = useDispatch();

  useEffect(() => {
    // adds lastActiveTime in localStorage after the browser is closed
    const handleBeforeUnload = () => {
      localStorage.setItem("lastActiveTime", Date.now());
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const lastActiveTime = localStorage.getItem("lastActiveTime");
      // logs out the user if its 30mins past the time in localStorage
      if (lastActiveTime && Date.now() - lastActiveTime > 30 * 60 * 1000) {
        // perform logout action here
        dispatch(signout());
        localStorage.removeItem("lastActiveTime");
        clearInterval(intervalId);
        // remove the localStorage if the time between you closed the browser and the time you open doesnt exceed 30mins
      } else if (
        lastActiveTime &&
        Date.now() - lastActiveTime < 30 * 60 * 1000
      ) {
        localStorage.removeItem("lastActiveTime");
      }
      // this function runs in the background for every 3sec while the browser is closed
    }, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, [lastActiveTime]);

  return children;
}
