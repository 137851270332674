import { ErrorMessage, Field, useField } from "formik";
import { Grid, MenuItem, TextField } from "@material-ui/core";

import { InputAdornment } from "@mui/material";
import PropTypes from "prop-types";
import TextError from "./TextError";
import { useStyles } from "./styles";

const Selects = (props) => {
  const { label, options, icon, field, form, value } = props;
  const classes = useStyles();

  return (
    <TextField
      select
      /* variant="outlined" */
      fullWidth
      value={value || " "}
      onChange={(e) => {
        form.setFieldValue(field?.name, e.target.value);
      }}
      onBlur={() => form.setFieldTouched(field?.name, true)}
      InputProps={{
        startAdornment: (
          <>
            {icon ? (
              <InputAdornment position="start">
                {typeof icon === "string" ? <img src={icon} /> : icon}
              </InputAdornment>
            ) : null}
          </>
        ),
        classes: {
          root: classes.textFieldRoot,
          notchedOutline: classes.textFieldOutline,
          input: classes.input,
        },
      }}
    >
      <MenuItem value=" ">{label}</MenuItem>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

const SelectInput = (props) => {
  const { name, ...rest } = props;
  const [field] = useField(name);

  return (
    <Grid container direction="column" gap={2}>
      <Field
        type="select"
        component={Selects}
        name={name}
        {...field}
        {...rest}
      />
      <ErrorMessage name={name} component={TextError} />
    </Grid>
  );
};

SelectInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
};

export default SelectInput;
