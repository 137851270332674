import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "api";

const initialState = {
  data: [],
  transactions: [],
  loading: true,
  error: false,
  errorMessage: undefined,
};

export const cardSlice = createSlice({
  name: "card",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get cards
    builder.addCase(getCards.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCards.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(getCards.rejected, (state, _) => {
      state.error = true;
      state.loading = false;
    });
    // freeze card
    builder.addCase(setCardAsFrozen.fulfilled, (state, action) => {
      // find the card that has been frozen and replace with the response
      // that is returned from the endpoint
      const cardIndex = state.data.findIndex(
        (card) => card.cardreferenceId === action.payload.cardreferenceId
      );
      state.data[cardIndex].frozenYN = action.payload[0].frozenYN;
    });
    builder.addCase(unfreezeCard.fulfilled, (state, action) => {
      // find the card that has been frozen and replace with the response
      // that is returned from the endpoint
      const cardIndex = state.data.findIndex(
        (card) => card.cardreferenceId === action.payload.cardreferenceId
      );
      state.data[cardIndex].frozenYN = action.payload[0].frozenYN;
    });
    // get card transactions
    builder.addCase(getCardTransactions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCardTransactions.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
    builder.addCase(getCardTransactions.fulfilled, (state, action) => {
      state.loading = false;
      state.transactions = action.payload.data;
    });
  },
});

export const orderCard = createAsyncThunk("orderCard", async (params) => {
  const { data } = await api.post("/ziyl/OrdercardfinxpV2", params);
  return data;
});

export const sendSmsLostCardVerification = createAsyncThunk(
  "sendSmsLostCardVerification",
  async (params) => {
    const { data } = await api.post("/ziyl/terminatecardfinxpV2", params);
    return data;
  }
);

export const setCardAsLost = createAsyncThunk("setLostCard", async (params) => {
  const { data } = await api.post("/ziyl/terminatecardfinxpV2", params);
  return data;
});

export const setCardAsFrozen = createAsyncThunk(
  "setCardAsFrozen",
  async (params) => {
    const { data } = await api.post("/ziyl/freezecardfinxpV3", params);
    return data;
  }
);

export const unfreezeCard = createAsyncThunk("unfreezeCard", async (params) => {
  const { data } = await api.post("/ziyl/freezecardfinxpV3", params);
  return data;
});

export const sendSmsShowPinVerification = createAsyncThunk(
  "sendSmsShowPinVerification",
  async (params) => {
    const { data } = await api.post("/ziyl/cardotpfinxp", params);
    return data;
  }
);

export const showCardPinNumber = createAsyncThunk(
  "showCardPinNumber",
  async (params) => {
    const { data } = await api.post("/ziyl/showcardpinfinxp", params);
    return data;
  }
);

export const sendSmsShowCardVerification = createAsyncThunk(
  "sendSmsShowCardVerification",
  async (params) => {
    const { data } = await api.post("/ziyl/cardotpfinxp", params);
    return data;
  }
);

export const showCardDetails = createAsyncThunk(
  "showCardDetails",
  async (params) => {
    const { data } = await api.post("/ziyl/showcardfinxpV2", params);
    return data;
  }
);

export const getCards = createAsyncThunk("getCards", async () => {
  const { data } = await api.get("ziyl/getcardsfinxpV2");
  return data;
});

export const sendSmsOrderCardVerification = createAsyncThunk(
  "sendSmsOrderCardVerification",
  async (params) => {
    const { data } = await api.post("/ziyl/cardotpfinxp", params);
    return data;
  }
);

export const getCardTransactions = createAsyncThunk(
  "getCardTransactions",
  async (params) => {
    const { data } = await api.post(
      "/ziyl/getCardTransactionsfinxp",
      params
    );
    return data;
  }
);

export default cardSlice.reducer;
