import Header from "Components/Header";
import Footer from "Components/Footer/Footer";
import Box from "@mui/material/Box";

export function OneColumnLayout({ children, showSocialLinks = true }) {
  return (
    <Box component="main" display="flex" flexDirection="column" height="100vh">
      <Header />
      <Box mb={6}>{children}</Box>
      <Footer showSocialLinks={showSocialLinks} />
    </Box>
  );
}
