import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    backdrop: {
      backgroundColor: "rgba(255,255,255, 0.3)!important",
      zIndex: "5",
    },
  };
});

export default useStyles;
