import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => {
  return {
    textFieldRoot: {
      borderRadius: "999px!important",
      background: "#f9f9f9",
    },
    input: {
      borderRadius: "revert!important",
      color: "rgba(0, 0, 0)!important",
      padding: ".75rem 0!important ",
      fontFamily: "Mukta-Regular!important",
      background: "#f9f9f9!important",
      fontSize: `${theme.typography.regularFont}!important`,
    },
    endIcon: {
      "& svg": { fill: "#086afb!important" },
    },
    iconRootStart: {
      padding: "0 .5rem 0 1rem",
    },
    iconRootEnd: {
      padding: "0 .35rem 0 0",
    },
  };
});

export const useTextAreaStyles = makeStyles((theme) => {
  return {
    textFieldRoot: {
      borderRadius: "40px!important",
      background: "#f9f9f9",
      border: "transparent!important",
      outline: "none!important",
    },
    input: {
      borderRadius: "revert!important",
      color: "rgba(0, 0, 0)!important",
      fontFamily: "Mukta-Regular!important",
      background: "#f9f9f9!important",
      fontSize: `${theme.typography.regularFont}!important`,
    },
  };
});
