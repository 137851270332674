import { getColor } from "../color";
export function Loading({ size = 14, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 18 18"
      fill={getColor(color) || color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C8.64497 0 8.35714 0.287807 8.35714 0.642857V4.92857C8.35714 5.2836 8.64497 5.57143 9 5.57143C9.35505 5.57143 9.64286 5.2836 9.64286 4.92857V0.642857C9.64286 0.287807 9.35505 0 9 0ZM3.09375 2.45089C2.92922 2.45089 2.76392 2.51287 2.63839 2.63839C2.38734 2.88944 2.38734 3.29135 2.63839 3.54241L5.66518 6.57589C5.91619 6.82693 6.32486 6.82695 6.57589 6.57589C6.82695 6.32484 6.82693 5.91621 6.57589 5.66518L3.5424 2.63839C3.41689 2.51286 3.25828 2.45089 3.09375 2.45089ZM14.9062 2.45089C14.7417 2.45089 14.5831 2.51286 14.4576 2.63839L11.4241 5.66518C11.173 5.91624 11.1731 6.32486 11.4241 6.57589C11.6752 6.82695 12.0838 6.82695 12.3348 6.57589L15.3616 3.54241C15.6127 3.29136 15.6127 2.88945 15.3616 2.63839C15.2361 2.51287 15.0708 2.4509 14.9062 2.45089ZM0.642857 8.35714C0.287807 8.35714 0 8.64495 0 9C0 9.35503 0.287807 9.64286 0.642857 9.64286H4.92857C5.2836 9.64286 5.57143 9.35503 5.57143 9C5.57143 8.64495 5.2836 8.35714 4.92857 8.35714H0.642857ZM13.0714 8.35714C12.7164 8.35714 12.4286 8.64497 12.4286 9C12.4286 9.35505 12.7164 9.64286 13.0714 9.64286H17.3571C17.7122 9.64286 18 9.35505 18 9C18 8.64497 17.7122 8.35714 17.3571 8.35714H13.0714ZM6.12054 11.2366C5.95603 11.2366 5.79071 11.2986 5.66518 11.4241L2.63839 14.4576C2.38731 14.7086 2.38734 15.1105 2.63839 15.3616C2.88943 15.6126 3.29134 15.6127 3.5424 15.3616L6.57589 12.3348C6.82695 12.0838 6.82693 11.6751 6.57589 11.4241C6.45036 11.2986 6.28504 11.2366 6.12054 11.2366ZM11.8795 11.2366C11.7149 11.2366 11.5496 11.2986 11.4241 11.4241C11.173 11.6752 11.1731 12.0838 11.4241 12.3348L14.4576 15.3616C14.7086 15.6127 15.1106 15.6127 15.3616 15.3616C15.6127 15.1106 15.6127 14.7086 15.3616 14.4576L12.3348 11.4241C12.2093 11.2986 12.044 11.2366 11.8795 11.2366ZM9 12.4286C8.64495 12.4286 8.35714 12.7164 8.35714 13.0714V17.3571C8.35714 17.7122 8.64495 18 9 18C9.35503 18 9.64286 17.7122 9.64286 17.3571V13.0714C9.64286 12.7164 9.35503 12.4286 9 12.4286Z"
        fill={getColor(color) || color}
      />
    </svg>
  );
}
