import FacebookIcon from "assets/icons/Facebook";
import facebook from "assets/images/facebook.png";
import EmailIcon from "assets/icons/Email";
import TwitterIcon from "assets/icons/Twitter";
import twitter from "assets/images/twitter.png"
import WhatsappIcon from "assets/icons/Whatsapp";
import instagram from "assets/images/instagram.png"
import linkedIn from "assets/images/LinkedIn.png"
import youtube from "assets/images/yt.png"
import medium from "assets/images/medium.png"

export const socialLinks = [
  { icon: twitter, to: "https://twitter.com/zazoomoney", bgColor: "rgba(0, 172, 238, 0.1)" },
  { icon: linkedIn, to: "https://facebook.com/zazoo.money", bgColor: "rgba(0, 114, 177, 0.1)" },
  { icon: instagram, to: "https://instagram.com/zazoo.money", bgColor: "rgba(138, 58, 105, 0.1)" },
  { icon: facebook, to: "https://facebook.com/zazoo.money", bgColor: "rgba(66, 103, 178, 0.1)" },
  { icon: youtube, to: "https://youtube.com/channel/UC5xQsooiJjEGBfomTVh9ZcA", bgColor: "rgba(255, 0, 0, 0.1)" },
  { icon: medium, to: "https://medium.com/@zazoomoney", bgColor: "rgba(25,25,25,0.1)" },
]

export const footerLinks = {
  personal: "/app/register/personal",
  business: "/app/register/business",
  helpCenter: "#",
  EUBankAccount: "#",
  SWIFTSEPA: "#",
  zazooCard: "#",
  openIBANAccount: "#",
  recurringPayments: "#",
  batchPayments: "#",
  ourStory: "#",
  newsRoom: "/blog",
  media: "#",
  privacy: "#",
  terms: "#"
}

export const contactLinks = [
  {
    icon: <EmailIcon color="medium-grey" />,
    to: "mailto:insertemailhere@xyz.com",
  },
  { icon: <TwitterIcon color="medium-grey" />, to: "https://twitter.com/" },
  {
    icon: <WhatsappIcon color="medium-grey" />,
    to: "https://www.whatsapp.com/",
  },
  {
    icon: <FacebookIcon color="medium-grey" />,
    to: "https://www.facebook.com/",
  },
];
