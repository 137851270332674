import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "api";

const initialState = {
  data: {
    daily: {},
    weekly: {},
    monthly: {},
  },
  loading: true,
  error: false,
  errorMessage: undefined,
};

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get limits
    builder.addCase(getLimits.fulfilled, (state, action) => {
      state.data.daily = action.payload;
      state.loading = false;
    });
    builder.addCase(getLimits.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const getLimits = createAsyncThunk("getLimits", async (params) => {
  const { data } = await api.post("/ziyl/getlimitsfinxp", params);
  return data;
});

export default settingSlice.reducer;
