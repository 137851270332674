import CardWithNavBar from "Components/CardWithNavBar/CardWithNavBar";

const Test = () => {
  return (
    <>
      <CardWithNavBar />
    </>
  );
};

export default Test;
