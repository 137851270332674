import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const METHODS = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
};

export const settingV2 = createApi({
  reducerPath: "settingV2",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_APIURL,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem("accessToken");
      const tokenZiyl = localStorage.getItem("token_ziyl");
      if (accessToken && tokenZiyl) {
        headers.set("Content-Type", "application/json");
        headers.set("AuthorizationFinxp", `Bearer ${accessToken}`);
        headers.set("Authorization", `Bearer ${tokenZiyl}`);
        return headers;
      }
    },
  }),
  tagTypes: ["settingV2"],
  endpoints: (builder) => ({
    requestUpdateAccountLimits: builder.query({
      query: ({ account_id, type, limit }) => ({
        url: `/ziyl/updatelimitsfinxp`,
        method: METHODS.PUT,
        body: {
          account_id,
          type,
          limit
        }
      }),
    }),
    getLimits: builder.query({
      query: ({ account_id }) => ({
        url: `/ziyl/getlimitsfinxp`,
        method: METHODS.POST,
        body: {
          account_id,
        },
      })
    }),
  }),
});

export const {
  useLazyRequestUpdateAccountLimitsQuery,
  useLazyGetLimitsQuery,
  useGetLimitsQuery,
} = settingV2;
