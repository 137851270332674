import { getColor } from "../color";
export function EyeClosed({ size = 14, color }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 14 14"
      fill={getColor(color) || color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.21763 4.33399L0.977178 4.54899L1.8016 5.5466L2.04206 5.3316C3.14988 4.34259 4.90178 3.15579 6.9886 3.15579C9.07542 3.15579 10.8273 4.34259 11.9351 5.3316L12.1756 5.5466L13 4.54899L12.7596 4.33399C11.4886 3.19879 9.46187 1.83998 6.9886 1.83998C4.51533 1.83998 2.48862 3.19879 1.21763 4.33399ZM6.35307 10.354V12.16H7.61547V10.354C7.95039 10.3196 8.2939 10.268 8.63741 10.182L9.16985 11.902L10.3721 11.4978L9.83969 9.78642C10.1574 9.65742 10.4752 9.50262 10.7843 9.32202L11.7805 10.7496L12.8025 9.97562L11.8578 8.62542C12.167 8.40182 12.4675 8.16102 12.7509 7.90302L12.9914 7.68802L12.167 6.69041L11.9265 6.90541C10.8187 7.90302 9.05821 9.08122 6.97138 9.08122C4.88456 9.08122 3.13266 7.89442 2.02484 6.90541L1.78438 6.69041L0.959961 7.68802L1.20042 7.90302C1.4924 8.15242 1.78438 8.40182 2.09354 8.62542L1.15748 9.97562L2.17942 10.7496L3.1756 9.32202C3.48476 9.49402 3.80251 9.64882 4.12025 9.78642L3.58781 11.4978L4.7901 11.902L5.32254 10.182C5.66605 10.268 6.00956 10.3196 6.35307 10.354Z"
        fill={getColor(color) || color}
      />
    </svg>
  );
}
