import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "api";

const initialState = {
  data: {},
  resetPasswordToken: undefined,
  loading: true,
  error: false,
  errorMessage: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth(state) {
      state.data = {};
      state.loading = false;
    },
    signout() {
      // Note that this should be left intentionally empty.
      // Clearing redux state and localSorage happens in store.js.
    },
  },
  extraReducers: (builder) => {
    // signin
    builder.addCase(signin.fulfilled, (state, action) => {
      // if the user login in normally
      if (action.payload.message === "Logged in successfully") {
        state.data = action.payload;
        state.loading = false;
      }

      // if the users first time login
      if (action.payload.message === "Change Password") {
        state.resetPasswordToken = action.payload.access_token;
        state.loading = false;
      }

      // if the password expired
      if (
        action.payload.message
          .toLowerCase()
          .includes("your password is expired")
      ) {
        state.resetPasswordToken = action.payload.token;
        state.loading = false;
      }
    });
    builder.addCase(signin.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
    // change password
    builder.addCase(changePassword.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(changePassword.rejected, (state) => {
      state.error = true;
    });
  },
});

export const signin = createAsyncThunk("signin", async (params) => {
  const { data } = await api.post("/ziyl/loginfinxp", params);
  return data;
});

export const changePassword = createAsyncThunk(
  "changePassword",
  async (params) => {
    const { data } = await api.put("/ziyl/updatePasswordfinxp", params);
    return data;
  }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async (params) => {
    const { data } = await api.post("/ziyl/resetPasswordfinxp", params);
    return data;
  }
);

export const forgotPassword = createAsyncThunk(
  "resetPassword",
  async (params) => {
    const { data } = await api.post("/ziyl/RequestForgotPasswordfinxp", params);
    return data;
  }
);

export const { resetAuth } = authSlice.actions;
export const { signout } = authSlice.actions;

export default authSlice.reducer;
