import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const REQUESTTYPE = {
  updateProfile: "Update Profile Request",
  updateAddress: "Update Address Request",
  updatePassword: "Update Password Request"
}
export const profileV2 = createApi({
  reducerPath: "profileV2",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_APIURL,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem("accessToken");
      const tokenZiyl = localStorage.getItem("token_ziyl");
      if (accessToken && tokenZiyl) {
        headers.set("Content-Type", "application/json");
        headers.set("AuthorizationFinxp", `Bearer ${accessToken}`);
        headers.set("Authorization", `Bearer ${tokenZiyl}`);
        return headers;
      }
    },
  }),
  keepUnusedDataFor: 30,
  tagTypes: ["profileV2"],
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => ({
        url: `/ziyl/profilefinxp`,
      }),
      invalidatesTags: ["profileV2"],
    }),
    createTicketRequest: builder.query({
      query: ({
        userEmail,
        payloadRequest,
        requestType,
      }) => ({
        url: '/ziyl/createticketfinxp',
        method: "POST",
        body: {
          type: requestType,
          ticketValue: [payloadRequest],
          dateSubmitted: new Date(),
          receive_email: userEmail
        }
      })
    })
  }),
});

export const {
  useGetProfileQuery,
  useLazyCreateTicketRequestQuery,
} = profileV2;
