import { getColor } from "../color";
export function Twitter({ color, size = 14 }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 10 9"
      fill={getColor(color) || color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.96484 2.67858C9.35547 2.39527 9.70703 2.0553 10 1.63978C9.60938 1.80976 9.21875 1.92309 8.82812 1.96086C9.25781 1.69644 9.57031 1.33758 9.72656 0.865393C9.31641 1.09204 8.88672 1.26203 8.41797 1.33758C8.22266 1.1487 7.98828 0.997605 7.73438 0.884281C7.48047 0.770957 7.20703 0.714294 6.91406 0.714294C6.54297 0.714294 6.21094 0.808731 5.89844 0.978717C5.58594 1.16759 5.33203 1.41313 5.15625 1.71532C4.96094 2.01752 4.88281 2.35749 4.88281 2.69747C4.88281 2.84857 4.88281 2.99967 4.92188 3.15076C4.08203 3.11299 3.30078 2.92412 2.55859 2.54637C1.81641 2.18751 1.21094 1.69644 0.703125 1.07315C0.507812 1.39424 0.410156 1.73421 0.410156 2.07418C0.410156 2.41416 0.488281 2.73524 0.664062 3.01855C0.820312 3.32075 1.05469 3.5474 1.32812 3.73627C0.996094 3.73627 0.683594 3.64184 0.410156 3.47185V3.50962C0.410156 3.98181 0.566406 4.39733 0.878906 4.75619C1.19141 5.13394 1.58203 5.36059 2.05078 5.45502C1.85547 5.4928 1.67969 5.51168 1.50391 5.51168C1.38672 5.51168 1.25 5.51168 1.13281 5.4928C1.25 5.88943 1.48438 6.21052 1.83594 6.47494C2.1875 6.73936 2.57812 6.85269 3.04688 6.85269C2.28516 7.41931 1.42578 7.70262 0.488281 7.70262C0.292969 7.70262 0.136719 7.70262 0 7.68373C0.9375 8.28813 1.99219 8.57144 3.14453 8.57144C4.33594 8.57144 5.39062 8.28813 6.32812 7.68373C7.16797 7.15488 7.83203 6.45605 8.30078 5.54946C8.75 4.71842 8.98438 3.83071 8.98438 2.92412C8.98438 2.81079 8.96484 2.73524 8.96484 2.67858Z"
        fill={getColor(color) || color}
        fillOpacity="1"
      />
    </svg>
  );
}
