import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const paymentV2 = createApi({
  reducerPath: "paymentV2",
  tagTypes: ["paymentV2"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_APIURL,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem("accessToken");
      const tokenZiyl = localStorage.getItem("token_ziyl");
      if (accessToken && tokenZiyl) {
        headers.set("Content-Type", "application/json");
        headers.set("Authorization", `Bearer ${tokenZiyl}`);
        headers.set("Authorizationfinxp", `Bearer ${accessToken}`);
        return headers;
      }
    },
  }),
  endpoints: (builder) => ({
    initiatePayment: builder.mutation({
      query: ({ paymentData }) => {
        return {
          url: `/ziyl/initiatepaymentfinxp`,
          method: "POST",
          body: paymentData,
        };
      },
      invalidatesTags: ["paymentV2"],
    }),

    otpRequest: builder.mutation({
      query: ({ otpData }) => ({
        url: `/ziyl/otprequestfinxp`,
        method: "POST",
        body: otpData,
      }),
      invalidatesTags: ["paymentV2"],
    }),

    processPayment: builder.mutation({
      query: ({ processPaymentData }) => ({
        url: `/ziyl/processpaymentfinxp`,
        method: "POST",
        body: processPaymentData,
      }),
      invalidatesTags: ["paymentV2"],
    }),

    ibanCheck: builder.mutation({
      query: ({ ibanDetails }) => ({
        url: `/ziyl/ibancheck`,
        method: "POST",
        body: ibanDetails,
      }),
    }),
  }),
});

export const {
  useInitiatePaymentMutation,
  useOtpRequestMutation,
  useProcessPaymentMutation,
  useIbanCheckMutation,
} = paymentV2;
