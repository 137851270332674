import { configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import authSlice from "redux/auth/authSlice";
import accountSlice from "redux/account/accountSlice";
import transactionSlice from "redux/transaction/transactionSlice";
import settingSlice from "redux/setting/settingSlice";
import beneficiariesSlice from "redux/beneficiary/beneficiarySlice";
import registrationSlice from "redux/registration/registrationSlice";
import profileSlice from "redux/profile/profileSlice";
import searchSlice from "redux/search/searchSlice";
import paymentSlice from "redux/payment/paymentSlice";
import cardSlice from "redux/card/cardSlice";
import { registrationV2 } from "redux/registrationV2/registrationV2Slice";
import { transactionV2 } from "redux/transactionV2/transactionv2Slice";
import { beneficiaryV2 } from "redux/beneficiary/beneficiaryV2Slice";
import { cardsV2 } from "redux/card/cardV2Slice";
import { authV2 } from "redux/auth/authSliceV2";
import { accountV2 } from "redux/account/accountSliceV2";
import { profileV2 } from "redux/profile/profileSliceV2";
import { paymentV2 } from "redux/payment/paymentSliceV2";
import { verifyMobileSlice } from "redux/verifyMobile/verifyMobileSlice";
import { unlockAccountSlice } from "redux/unlockAccount/unlockAccountSlice";
import { settingV2 } from "redux/settingV2/settingV2Slice";
import storage from "redux-persist/lib/storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

export const reducers = combineReducers({
  auth: authSlice,
  account: accountSlice,
  transaction: transactionSlice,
  setting: settingSlice,
  beneficiaries: beneficiariesSlice,
  profile: profileSlice,
  registration: registrationSlice,
  search: searchSlice,
  payment: paymentSlice,
  card: cardSlice,
  [registrationV2.reducerPath]: registrationV2.reducer,
  [transactionV2.reducerPath]: transactionV2.reducer,
  [beneficiaryV2.reducerPath]: beneficiaryV2.reducer,
  [cardsV2.reducerPath]: cardsV2.reducer,
  [authV2.reducerPath]: authV2.reducer,
  [accountV2.reducerPath]: accountV2.reducer,
  [profileV2.reducerPath]: profileV2.reducer,
  [paymentV2.reducerPath]: paymentV2.reducer,
  [verifyMobileSlice.reducerPath]: verifyMobileSlice.reducer,
  [settingV2.reducerPath]: settingV2.reducer,
  [unlockAccountSlice.reducerPath]: unlockAccountSlice.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === "auth/signout") {
    localStorage.removeItem("persist:root");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("token_ziyl");
    localStorage.removeItem("token_type");
    localStorage.removeItem("expires_in");
    state = {};
  }
  return reducers(state, action);
};

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  blacklist: ["transactionV2", "beneficiaryV2"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
// as per redux with redux persist configuration
// If using Redux-Persist, you should specifically ignore all the action types it dispatches:
// https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      thunk,
      registrationV2.middleware,
      transactionV2.middleware,
      beneficiaryV2.middleware,
      cardsV2.middleware,
      authV2.middleware,
      accountV2.middleware,
      profileV2.middleware,
      paymentV2.middleware,
      verifyMobileSlice.middleware,
      settingV2.middleware,
      unlockAccountSlice.middleware
    ),
});

export const persistor = persistStore(store);

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};
