import { countries, nationalities } from "data/ISO3166";
import { companyDetailsRole } from "data/options";

export const filterObject = (obj, condition) =>
  Object.fromEntries(Object.entries(obj).filter(condition));

export function formatSwitchStringToBoolean(value) {
  if (value === "N") return false;
  else if (value === "Y") return true;
}

export function convertImageToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      resolve(reader.result.split("base64,")[1]);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
}

/**
 * @param amount string
 * @param currency string
 *
 * @description expected value for parameter is either +1.0 or -1.0
 * expected return value is €1.0 or €-1.0
 *
 * @returns string
 */
export function formatAmountTableValue(amount = "", currency = "") {
  if (!amount || !currency) return;
  // if (!amount.split(".")[1]) return

  // if the amount is a whole number, for example, "+10"
  if (!amount.split(".")[1]) {
    if (amount > 0) {
      return `${getCurrency(currency)}${amount.slice(1)}`;
    } else {
      return `${amount.slice(0, 1)}${getCurrency(currency)} ${amount.slice(1)}`;
    }
  }

  // is the amount is a decimal number, for example, "-0.5"
  if (amount > 0) {
    if (amount.split(".")[1].length > 1) {
      return `${getCurrency(currency)}${amount.slice(1)}kkk`;
    } else {
      return `${getCurrency(currency)}${amount.slice(1)}0`;
    }
  } else {
    if (amount.split(".")[1].length > 1) {
      return `${amount.slice(0, 1)}${getCurrency(currency)}${amount.slice(1)}`;
    } else {
      return `${amount.slice(0, 1)}${getCurrency(currency)}${amount.slice(1)}0`;
    }
  }
}

export function getCurrency(currency = "") {
  return currency === "EUR" ? "€" : "$";
}

export function formatDateTableValue(date = "") {
  return date.split("-").reverse().join("/");
}

export function formatDate(date) {
  const _date = new Date(date);
  const year = _date.getFullYear();
  const month = String(_date.getMonth() + 1).padStart(2, "0");
  const day = String(_date.getDate()).padStart(2, "0");

  return [year, month, day].join("-");
}

//convert date to dd/mm/yyyy, hr:mm:ss
export function convertDate(timestamp,showTime=true) {
  let currentTimestamp = new Date(parseInt(timestamp));

   if(showTime === true) {
      return new Intl.DateTimeFormat("en-UK", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(timestamp);
  } else {

    return new Intl.DateTimeFormat("en-UK", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }).format(timestamp);

  }

}



export function getCurrentDate() {
  const date = new Date();
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
}

export function calculateFirstDateOfCurrentMonth() {
  const date = new Date();
  date.setDate(1); // get the first date of the current month
  return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
}

export function scrollToTopOfPage() {
  window.document.body.scrollTop = 0;
  window.document.documentElement.scrollTop = 0;
}

export const arrayChecker = (arr) => {
  return arr && Array.isArray(arr) && arr.length > 0 ? true : false;
};

// set object value for countryOfBirth and nationality in sync with initialValues and form value
export const countryValueFromLocalStorage = (type, value) => {
  const selectedDataCountryOfBirth = {};
  if (value) {
    const arrCountry = type === "country" ? countries : nationalities;
    const countryObj = arrCountry.find((country) => country.alpha3 === value);
    if (countryObj && Object.keys(countryObj).length > 0) {
      Object.assign(selectedDataCountryOfBirth, {
        label: type === "country" ? countryObj?.name : countryObj?.nationality,
        value: countryObj?.alpha3,
      });
    }
  }
  return Object.keys(selectedDataCountryOfBirth).length > 0
    ? selectedDataCountryOfBirth
    : null;
};

export const countriesValueFromLocalStorage = (value) => {
  let selectedCountries = [];
  if (arrayChecker(value)) {
    selectedCountries = countries.filter((country) =>
      value.includes(country?.alpha3)
    );
  }
  return selectedCountries.length > 0 ? selectedCountries : null;
};

export const commaSeparator = (index, arr) => {
  if (!arrayChecker(arr)) return "";
  return index + 1 === arr.length ? "" : ", ";
};

export const firstCharIsMinus = (value) => {
  if (!value) return "-";
  const amount = value ? value : "";
  const firstAmountChar = amount ? amount.charAt(0) : null;
  const charIsMinus = firstAmountChar === "-";
  return charIsMinus;
};

export const formatCurrencyToLocalEn = (currency) => {
  const trimString = currency.replace(/[^0-9.-]+/g, "");
  if (trimString) {
    return parseFloat(trimString).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return 0.0;
};

export const formatCurrency = (value, currency) => {
  const hasMinus = firstCharIsMinus(value);
  if (hasMinus && value) {
    const removedFirstChar = value.slice(1);
    return `-${getCurrency(currency)}${formatCurrencyToLocalEn(
      removedFirstChar
    )}`;
  } else if (!hasMinus && value) {
    return `${getCurrency(currency)}${formatCurrencyToLocalEn(value)}`;
  }
  return `${getCurrency(currency)}0.00`;
};

export function getCurrencySymbol(curreny) {
  //get currency symbol
  switch (curreny) {
    case "EUR":
      return '<p>&#8364;</p>';
    case "USD":
      return "&#36";
    case "GBP":
      return "&#163";
    case "YEN":
      return "&#165";
    default:
      return "&#8364";
  }
  

}

export const truncateText = (value) => {
  /*
    -will check a word or upt to 3 words then truncate
     -if 3 words
      -truncate the first 4 letter of word then show only the first letter of second and thirs word ie "clos..B = Closing Balance"
    -if 1 word
     -if word is less than 7 char dont truncate else truncate ie "ClosingBalance = Closing.."
  */
  const text = value.split(" ") || [];
  if (text.length > 1) {
    const [firstWord, secondWord, thirdWord] = text;
    return `${firstWord.substring(0, 4)}...${secondWord.substring(0, 1)}${
      thirdWord ? `..${thirdWord.substring(0, 1)}` : ""
    }`;
  }
  return text.length > 0 && text[0].length > 7
    ? `${text[0].substring(0, 6)}...`
    : text[0];
};

export function getPendingAmount(avlbal, currentBalance) {
  if (!avlbal || !currentBalance) {
    return 0.0;
  }
  const pendingAmount = Math.abs(Number(currentBalance) - Number(avlbal));
  //use toFixed(2) to format nuber to 2 decimal places
  return pendingAmount.toFixed(2);
}

const chechIfResponseIsError = (response) => {
  const errorCodes = [
    500, 400, 401, 403, 404, 405, 406, 409, 422, 429,
    "500", "400", "401", "403", "404", "405", "406", "409", "422", "429",
  ];
  if (response && (errorCodes.includes(response.status) || errorCodes.includes(response.code))) {
    return true;
  }
  return false;
};

export const getUserActiveCards = (cards) => {
  if (!cards || chechIfResponseIsError(cards)) return [];
  const activeCards = cards.filter((card) => card.lostYN === "N");
  return cards.filter((card) => card.lostYN === "N");
};

export const catchErrorMessage = (error) => {
  const errorMessage = {
    code: 400,
    message: "Exception error. Please try again",
    status: "Failed",
    data: null,
  };
  if (error) {
    const errObject = JSON.parse(error);
    Object.assign(errorMessage, { ...errObject });
  }
  return errorMessage;
};

export const checkStringIfContainsNumbers = (value) => {
  return /^\d+$/.test(value);
};

export const appRoutes = {
  registerPersonal: "/register/personal",
  signin: "/login",
  /* registerBusiness: "/register/business", */
  registerBusiness: "/register/business/v2",
  registerBusinessV2: "/register/business/v2",
  registrationStatus: "/registration/status",
  dashboard: "/dashboard",
  transactions: "/dashboard/transactions",
  card: "/dashboard/card",
  payments: "/dashboard/payments",
  statements: "/dashboard/statements",
  payees: "/dashboard/payees",
  profile: "/dashboard/profile",
  changePassword: "/change-password/:resetToken",
  expiredPassword: "/change-password",
  forgottenPassword: "/forgottenpassword",
  test: "/components",
};
