import PropTypes from "prop-types";
import Swal from "sweetalert2";

export function  DisplayAlert({
  title,
  text,
  icon,
  confirmHandler = () => {},
}) {
    Swal.fire({
        title: title,
        text: text,
        icon: icon,
        confirmButtonColor: '#E7038E',
    })
    .then((action) => {
      if(action.isConfirmed) {
        confirmHandler();
      }
    })
}

DisplayAlert.defaultProps = {
  title: "",
  text: "",
  icon: "",
  confirmHandler: () => {},
};

DisplayAlert.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  confirmHandler: PropTypes.func,
};

export const { useSwalAlert } = DisplayAlert;

