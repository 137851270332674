import axios from "axios";
import { persistor } from "store";
import { appRoutes } from "utils/helpers";

const baseURL = process.env.REACT_APP_APIURL;

export const api = axios.create({
  baseURL,
});

// this will append the headers before the request is sent
// on all requests when the user is authenticated
api.interceptors.request.use(
  (config) => {
    config.headers["AuthorizationFinxp"] = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    config.headers["Authorization"] = `Bearer ${localStorage.getItem(
      "token_ziyl"
    )}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// if the response code is 401 axios interceptor will
// redirect to the login page
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("🚀 ~ file: api.js:35 ~ error:", error);
    if (error.request.status === 401) {
      localStorage.clear();
      persistor.purge();
      // handling error codes to return message to FE
      if (error?.response?.data?.code === 401) {
        // console.log("going instead");
        const errorData = error?.response?.data
          ? JSON.stringify(error?.response?.data)
          : error?.response?.data;
        return Promise.reject(errorData);
      }
      // window.location.href = process.env.REACT_APP_BASENAME + appRoutes.signin;
    }
    // handling error codes to return message to FE
    if (
      error?.response?.data?.code === 400 ||
      error?.response?.data?.code === 500
    ) {
      const errorData = error?.response?.data
        ? JSON.stringify(error?.response?.data)
        : error?.response?.data;
      return Promise.reject(errorData);
    }
    // handling error codes to return message to FE
    if (error?.response?.status === 400) {
      const errorData = error?.response?.data
        ? JSON.stringify(error?.response?.data)
        : error?.response?.data;
      return Promise.reject(errorData);
    }

    return Promise.reject(error);
  }
);
