import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const verifyMobileSlice = createApi({
  reducerPath: "verifyMobileSlice",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_APIURL }),
  tagTypes: ["VerifyMobileSlice"],
  endpoints: (builder) => ({
    verifyMobile: builder.mutation({
      query: (verifyMobileData) => ({
        url: `/ziyl/verifyemailfinxp`,
        headers: {
          "content-type": "application/json",
        },
        method: "POST",
        body: verifyMobileData,
        /* responseHandler: (response) => response.text(), */
      }),
    }),
  }),
});

export const { useVerifyMobileMutation } = verifyMobileSlice;
