import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const METHODS = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
};
export const cardsV2 = createApi({
  reducerPath: "cardsV2",
  tagTypes: ["cardsV2"],
  keepUnusedDataFor: 30,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_APIURL,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem("accessToken");
      const tokenZiyl = localStorage.getItem("token_ziyl");
      if (accessToken && tokenZiyl) {
        headers.set("Content-Type", "application/json");
        headers.set("AuthorizationFinxp", `Bearer ${accessToken}`);
        headers.set("Authorization", `Bearer ${tokenZiyl}`);
        return headers;
      }
    },
  }),
  endpoints: (builder) => ({
    getCardTransactions: builder.query({
      query: ({ account_id, from_date, to_date, type, card_id }) => ({
        url: `/ziyl/getCardTransactionsfinxp`,
        method: METHODS.POST,
        body: {
          account_id,
          from_date,
          to_date,
          type,
          card_id,
        },
      }),
      invalidatesTags: ["cardsV2"],
    }),
    sendSmsShowPinVerification: builder.query({
      query: ({ type }) => ({
        url: `/ziyl/cardotpfinxp`,
        method: "POST",
        body: { type },
      }),
    }),
    // orderCard: builder.query({
    //   query: ({ accountUuid, email, cardType, currency, otp }) => ({
    //     url: "/ziyl/OrdercardfinxpV2",
    //     method: METHODS.POST,
    //     body: {
    //       accountUuid,
    //       email,
    //       cardType,
    //       currency,
    //       code: otp,
    //     },
    //   }),
    // }),
    // sendSmsLostCardVerification: builder.query({
    //   query: ({ identifier, type, last4digitsCC, email }) => ({
    //     url: "/ziyl/terminatecardfinxpV2",
    //     method: METHODS.POST,
    //     body: {
    //       identifier,
    //       type,
    //       last4digitsCC,
    //       email,
    //     },
    //   }),
    // }),

    orderCardTwo: builder.mutation({
      query: ({ accountUuid, email, cardType, currency, otp }) => {
  
        return {
          url: "/ziyl/OrdercardfinxpV2",
          method: METHODS.POST,
          body: {
            cardType,
            accountUuid,
            currency,
            email,
            code: otp,
          },
          invalidatesTags: ["cardsV2"],
        };
      },
    }),
    sendSmsLostCardVerification: builder.query({
      query: ({ accountId, cardId }) => ({
        url: "/ziyl/terminatecardfinxpV2",
        method: METHODS.POST,
        body: {
          account_id: accountId,
          card_id: cardId,
        },
      }),
    }),
    showCardDetails: builder.query({
      query: ({ accountId, otp, cardId }) => ({
        url: `/ziyl/showcardfinxpV2`,
        method: "POST",
        body: {
          account_id: accountId,
          otp,
          card_id: cardId,
        },
      }),
    }),
    
    orderCard: builder.query({
      query: ({ accountUuid, firstname, lastname,email, cardType, currency, otp }) => ({
        url: "/ziyl/OrdercardfinxpV2",
        method: METHODS.POST,
        body: {
          accountUuid,
          firstname,
          lastname,
          email,
          cardType,
          currency,
          otp: otp,
        },
      }),
    }),
    sendSmsShowPinVerificationTwo: builder.mutation({
      query: ({ type }) => ({
        url: `/ziyl/cardotpfinxp`,
        method: "POST",
        body: {
          type,
        },
        invalidatesTags: ["cardsV2"],
      }),
    }),
    getCardV2: builder.query({
      query: () => ({
        url: `/ziyl/getcardsfinxpV2`,
        method: "GET",
      }),
      invalidatesTags: ["cardsV2"],
    }),
    freezeCard: builder.query({
      query: ({ freezeYN, account_id, cardId }) => ({
        url: `/ziyl/freezecardfinxpV3`,
        method: "POST",
        body: {
          freezeYN: freezeYN,
          account_id: account_id,
          card_id: cardId,
        },
      }),
      invalidatesTags: ["cardsV2"],
    }),
    showCardPinNumber: builder.query({
      query: ({ account_id, show, cardId }) => ({
        url: `/ziyl/showcardpinfinxp`,
        method: "POST",
        body: {
          account_id: account_id,
          show: show,
          card_id: cardId,
        },
      }),
      invalidatesTags: ["cardsV2"],
    }),
    showCardRegistrationFinxp: builder.mutation({
      query: ({ accountId }) => ({
        url: `/ziyl/showcardregistrationfinxpV2`,
        method: "POST",
        body: {
          account_id: accountId,
        },
      }),
      invalidatesTags: ["cardsV2"],
    }),
  }),
});

export const {
  useLazyGetCardTransactionsQuery,
  useLazySendSmsShowPinVerificationQuery,
  useSendSmsShowPinVerificationTwoMutation,
  useLazyOrderCardQuery,
  useOrderCardTwoMutation,
  useSendSmsLostCardVerificationQuery,
  useLazySendSmsLostCardVerificationQuery,
  useShowCardDetailsQuery,
  useLazyShowCardDetailsQuery,
  useLazyGetCardV2Query,
  useLazyFreezeCardQuery,
  useLazyShowCardPinNumberQuery,
  useShowCardRegistrationFinxpMutation,
} = cardsV2;
