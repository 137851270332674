import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const registrationV2 = createApi({
  reducerPath: "registrationV2",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_APIURL }),
  tagTypes: ["RegistrationV2"],
  endpoints: (builder) => ({
    registerBusiness: builder.mutation({
      query: ({ businessData, accessToken }) => ({
        url: `/ziyl/registrationfbusinessV2finxp`,
        method: "POST",
        headers: {
          "content-type": "application/json",
          AuthorizationBiz: `Bearer ${accessToken}`,
        },
        body: businessData,
      }),
    }),
    businessAuth: builder.mutation({
      query: () => ({
        url: `/ziyl/getAuthenticationTokenBusinessFinXP`,
        method: "POST",
        body: {
          client_id: "business-onboarding-client-staging",
        },
      }),
    }),
    smsAccountRegistrationVerification: builder.mutation({
      query: ({ identifier }) => ({
        url: `/ziyl/getregistrationotpfinxp`,
        method: "POST",
        body: {
          identifier,
        },
      }),
    }),
    sumsubToken: builder.query({
      query: (sumsubUserId) => {
        return {
          url: `/ziyl/sumsubToken/${sumsubUserId}`,
          method: "GET",
        };
      },
      invalidatesTags: ["RegistrationV2"],
    }),
  }),
});

export const {
  useRegisterBusinessMutation,
  useBusinessAuthMutation,
  useSmsAccountRegistrationVerificationMutation,
  useLazySumsubTokenQuery,
} = registrationV2;
