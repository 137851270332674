import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import { persistor, store } from "store";

import CssBaseline from "@material-ui/core/CssBaseline";
import HideWhenAuthenticated from "middleware/HideWhenAuthenticated";
import IdleSessionLogout from "middleware/IdleSessionLogout";
import Loadable from "Components/Loadable/Loadable";
import { PersistGate } from "redux-persist/integration/react";
import PrivateRoute from "middleware/PrivateRoute";
import { Provider } from "react-redux";
import RedirectSumsub from "Components/SumsubProcess/RedirectSumsub";
import SumsubWithLink from "Components/SumsubProcess/SumsubWithLink";
import Test from "Pages/Test/Test";
import { ThemeProvider } from "@material-ui/core/styles";
import VerifyMail from "Components/VerifyMail/VerifyMail";
import { appRoutes } from "utils/helpers";
import theme from "./styles/theme";
import LoadableBackground from "Components/LoadableBackground/LoadableBackground";
import SnackbarAlert from "Components/SnackbarAlert/SnackbarAlert";
import ForgottenPassword from "Pages/ForgottenPassword";
import ModalVerifyCode from "Pages/Card/Parts/ModalVerifyCode";

const RegisterBusinessV2 = Loadable(
  lazy(() => import("Pages/RegisterBusinessV2/index"))
);
const SignUp = Loadable(lazy(() => import("Pages/Register/index")));
const LogIn = Loadable(lazy(() => import("Pages/SignIn/index")));
const RegisterBusiness = Loadable(
  lazy(() => import("Pages/RegisterBusiness/index"))
);
const RegistrationStatus = Loadable(
  lazy(() => import("Pages/RegistrationStatus/index"))
);
const Dashboard = Loadable(lazy(() => import("Pages/MyAccount/index")));
const Transaction = Loadable(lazy(() => import("Pages/Transactions/index")));
const Card = Loadable(lazy(() => import("Pages/Card/index")));
const Payment = Loadable(lazy(() => import("Pages/Payments/index")));
const Statements = Loadable(lazy(() => import("Pages/Statements/index")));
const Beneficiary = Loadable(lazy(() => import("Pages/Beneficiary/index")));
const Profile = Loadable(lazy(() => import("Pages/Profile/index")));
const ChangePassword = Loadable(
  lazy(() => import("Pages/ChangePassword/index"))
);
const VerifyMobile = Loadable(lazy(() => import("Pages/VerifyMobile/index")));
const UnlockAccount = Loadable(lazy(() => import("Pages/UnlockAccount/index")));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LoadableBackground />
      <SnackbarAlert />
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
          <Suspense fallback={null}>
            <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
              <Routes>
                <Route
                  path={appRoutes.registerBusinessV2}
                  element={
                    <HideWhenAuthenticated>
                      <RegisterBusinessV2 />
                    </HideWhenAuthenticated>
                  }
                />
                <Route
                  path={appRoutes.signin}
                  element={
                    <HideWhenAuthenticated>
                      <LogIn />
                    </HideWhenAuthenticated>
                  }
                />
                <Route
                  path={appRoutes.registerPersonal}
                  element={
                    <HideWhenAuthenticated>
                      <SignUp />
                    </HideWhenAuthenticated>
                  }
                />
                <Route
                  path={appRoutes.registerBusiness}
                  element={
                    <HideWhenAuthenticated>
                      <RegisterBusiness />
                    </HideWhenAuthenticated>
                  }
                />
                <Route
                  path={appRoutes.registrationStatus}
                  element={
                    <HideWhenAuthenticated>
                      <RegistrationStatus />
                    </HideWhenAuthenticated>
                  }
                />
                <Route
                  path={appRoutes.dashboard}
                  element={
                    <PrivateRoute>
                      <IdleSessionLogout>
                        <Dashboard />
                      </IdleSessionLogout>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={appRoutes.transactions}
                  element={
                    <PrivateRoute>
                      <IdleSessionLogout>
                        <Transaction />
                      </IdleSessionLogout>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={appRoutes.card}
                  element={
                    <PrivateRoute>
                      <IdleSessionLogout>
                        <Card />
                      </IdleSessionLogout>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={appRoutes.payments}
                  element={
                    <PrivateRoute>
                      <IdleSessionLogout>
                        <Payment />
                      </IdleSessionLogout>
                    </PrivateRoute>
                  }
                />
                 <Route
                  path={appRoutes.statements}
                  element={
                    <PrivateRoute>
                      <IdleSessionLogout>
                        <Statements />
                      </IdleSessionLogout>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={appRoutes.payees}
                  element={
                    <PrivateRoute>
                      <IdleSessionLogout>
                        <Beneficiary />
                      </IdleSessionLogout>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={appRoutes.profile}
                  element={
                    <PrivateRoute>
                      <IdleSessionLogout>
                        <Profile />
                      </IdleSessionLogout>
                    </PrivateRoute>
                  }
                />
                <Route
                  path={appRoutes.changePassword}
                  element={<ChangePassword />}
                />

                <Route
                  path={appRoutes.forgottenPassword}
                  element={<ForgottenPassword />}
                />

                <Route
                  path={appRoutes.expiredPassword}
                  element={<ChangePassword />}
                />

                <Route path="/verify" element={<VerifyMail />}>
                  <Route
                    path="/verify/:code/:mobile/:uuid/:email"
                    exact
                    component={<VerifyMail />}
                  />
                </Route>
                <Route path="/verifymobile" element={<VerifyMobile />}>
                  <Route
                    path="/verifymobile/:code/:mobile/:uuid/:email"
                    component={<VerifyMobile />}
                  />
                </Route>

                <Route path="/unlockaccount" element={<UnlockAccount />}>
                  <Route
                    path="/unlockaccount/:code/:email"
                    component={<UnlockAccount />}
                  />
                </Route>
                {/* I do not know what this does so will leave it for now */}
                <Route path="/Sumsublinked" element={<RedirectSumsub />}>
                  <Route path="token" element={<SumsubWithLink />}></Route>
                </Route>
                <Route path={appRoutes.test} element={<Test />} />
              </Routes>
            </BrowserRouter>
          </Suspense>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
