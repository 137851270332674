import React from "react";
import SumsubWebSdk from "@sumsub/websdk-react";
import styles from "./style.module.css";
const SumsubWithLink = () => {
  const params = new URLSearchParams(window.location.search);
  const tokenUrl = params.get("token");

  const expirationHandler = (e) => {};
  const messageHandler = (e) => {};

  const errorHandler = (e) => {};
  return (
    <>
      <div className={styles.verificationlaststepContainer}>
        <div className={styles.titleContainer}>
          {" "}
          <h3 className={styles.title}>Sumsub</h3>
        </div>

        <form action="#" className={styles.formContainer}>
          <div className={styles.lineVerificstionA}>
            <p className={styles.tagline}>Get Ready for the KYC Process</p>
          </div>

          <div className={styles.lineVerificstionB}>
            <p className={styles.tagline}>
              <SumsubWebSdk
                // accessToken='_act-sbx-6f667be7-bc59-437d-8b86-b25ce8a55c1d'
                accessToken={tokenUrl}
                expirationHandler={expirationHandler}
                // config={config}
                // options={options}
                onMessage={messageHandler}
                onError={errorHandler}
              />
            </p>
          </div>
        </form>
      </div>
    </>
  );
};

export default SumsubWithLink;
