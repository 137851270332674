import styles from "./style.module.css";
import logo from "assets/images/ZazooLogo.svg";
import { footerLinks, socialLinks } from "data/links";
import { useViewPort } from "hooks/useViewPort";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export default function Footer({ showSocialLinks = true }) {
  const { isTablet } = useViewPort();
  return (
    <Box component="section" mt={"auto"}>
      {showSocialLinks && (
        <section className={[styles.socialLinks, "bg-white"].join(" ")}>
          <Box sx={{ maxWidth: "800px", margin: "0 auto" }}>
            <Container sx={{ maxWidth: "800px" }}>
              <Grid container spacing={0} px={"15px"}>
                {socialLinks.map((socialLink) => {
                  return (
                    <Grid item xs={2} key={socialLink?.icon}>
                      <div
                        style={{ "--color": socialLink.bgColor }}
                        className={styles.socialLinkIcon}
                      >
                        <a href={socialLink.to}>
                          <img src={socialLink.icon} />
                        </a>
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Container>
          </Box>
        </section>
      )}
      <footer className={styles.footer}>
        <Container px={"0px"}>
          <div className={styles.footerGrid}>
            <div className={[styles.logo].join(" ")}>
              <img
                className={styles.logoImage}
                src={logo}
                alt="zazoo company logo"
              />
              {isTablet && (
                <div>
                  <div className={styles.footerSocket}>
                    <a href={footerLinks.privacy}>Privacy</a>
                    <span> - </span>
                    <a href={footerLinks.terms}>Terms</a>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.links}>
              <div className={styles.linksGroup}>
                <h5>Start now</h5>
                <ul>
                  <li>
                    <a href={footerLinks.personal}>Personal</a>
                  </li>
                  <li>
                    <a href={footerLinks.business}>Business</a>
                  </li>
                  <li>
                    <a href={footerLinks.helpCenter}>Help center</a>
                  </li>
                </ul>
              </div>
              <div className={styles.linksGroup}>
                <h5>Personal</h5>
                <ul>
                  <li>
                    <a href={footerLinks.personal}>Open Personal Account</a>
                  </li>
                  <li>
                    <a href={footerLinks.SWIFTSEPA}>EU SEPA Transfer</a>
                  </li>
                  <li>
                    <a href={footerLinks.zazooCard}>Zazoo Debit Card</a>
                  </li>
                </ul>
              </div>
              <div className={styles.linksGroup}>
                <h5>Business</h5>
                <ul>
                  <li>
                    <a href={footerLinks.business}>Open Business Account</a>
                  </li>
                  <li>
                    <a href={footerLinks.recurringPayments}>
                      Recurring Payments
                    </a>
                  </li>
                  <li>
                    <a href={footerLinks.batchPayments}>Batch Payments</a>
                  </li>
                </ul>
              </div>
              <div className={styles.linksGroup}>
                <h5>About us</h5>
                <ul>
                  <li>
                    <a href={footerLinks.ourStory}>Our Story</a>
                  </li>
                  <li>
                    <a href={footerLinks.newsRoom}>NewsRoom</a>
                  </li>
                  <li>
                    <a href={footerLinks.media}>Media</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Grid container>
            <Grid item xs={12}>
              <div>
                <div className={styles.copyright}>
                  <p>
                    © 2022 Zazoo, Zazoo modern payments and GoZazoo are
                    copyright of IQGP Ltd | Zazoo is a trading name of IQGP Ltd,
                    a company registered in Cyprus with Registration number
                    HE435932, with its registered office at Dimostheni Severi
                    12, 6th Floor, Office 601, Nicosia, 1080, Cyprus. IQGP Ltd
                    designs and operates the Zazoo website and related products.
                    Zazoo is not a bank. Zazoo is a technical platform that
                    facilitates the opening and operating of an electronic money
                    accounts for both individuals and corporate customers. The
                    account enables users to make cross-border payments and also
                    the use of a debit card through its relationship with
                    regulated entity and electric money institution license
                    holder, FinXP Ltd, a company registered in Malta with
                    Registration number C 65783 which is regulated by the Malta
                    Financial Services Authority and who has their registered
                    office at Ardent Business Centre, No 4, Triq L-Oratorju,
                    Naxxar NXR 2505, Malta.
                  </p>
                </div>
                {!isTablet && (
                  <div className={styles.footerSocket}>
                    <a href={footerLinks.privacy}>Privacy</a>
                    <span> - </span>
                    <a href={footerLinks.terms}>Terms</a>
                  </div>
                )}
              </div>
            </Grid>
          </Grid>
        </Container>
      </footer>
    </Box>
  );
}
