import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    cardContainer: {
      marginTop: "3rem",
      maxWidth: 500,
      width: "100%",
      overflowWrap: "break-word",
      marginLeft: "auto",
      marginRight: "auto",
    },
    cardContent: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      gap: ".5rem",
      height: 150,
    },
    text: {
      textAlign: "center",
      fontFamily: "Nunito",
    },
  };
});

export default useStyles;
