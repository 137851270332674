import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  return {
    iconButtonRoot: {
      backgroundColor: "#f5faff!important",
      marginLeft: ".25rem!important",
    },
    iconRoot: {
      color: theme.palette.accentBlue,
    },
  };
});

export default useStyles;
