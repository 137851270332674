import clsx from "clsx";

import { useViewPort } from "hooks/useViewPort";
import style from "./button.module.css";
import LoadingIcon from "../../assets/icons/Loading";

export function Button({
  color,
  children,
  rightIcon,
  leftIcon,
  selected = false,
  loading = false,
  disabled = false,
  minWidth,
  minHeigt,
  marginRight,
  pageType = "",
  ...props
}) {
  const { isDesktop, isTablet } = useViewPort();
  return (
    <button
      style={{
        minWidth: minWidth,
        marginRight: marginRight,
        minHeight: minHeigt,
      }}
      className={[
        color && style[color],
        selected ? style.selected : null,
        style.btn,
        !rightIcon || !leftIcon ? style.noIcon : null,
        disabled ? `${style.grey} ${style.disabled}` : null,
      ].join(" ")}
      disabled={disabled}
      {...props}
    >
      <>
        {leftIcon && <span className={style.leftIcon}>{leftIcon}</span>}
        <span
          className={clsx(
            pageType === "cardPage" && isDesktop && isTablet ? style.blue : ""
          )}
        >
          {children}
        </span>
        {rightIcon && !loading && (
          <span className={style.rightIcon}>{rightIcon}</span>
        )}
        {loading && (
          <span className={style.rightIcon}>
            <LoadingIcon />
          </span>
        )}
      </>
    </button>
  );
}
