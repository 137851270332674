import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const unlockAccountSlice = createApi({
  reducerPath: "unlockAccountSlice",
  baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_APIURL }),
  tagTypes: ["UnlockAccountSlice"],
  endpoints: (builder) => ({
    unlockAccount: builder.mutation({
      query: (unlockAccountData) => ({
        url: `/ziyl/unlockAccount`,
        headers: {
          "content-type": "application/json",
        },
        method: "POST",
        body: unlockAccountData,
      }),
    }),
  }),
});

export const { useUnlockAccountMutation } = unlockAccountSlice;
