import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Skeleton } from "@mui/material";
import Container from "@mui/material/Container";

import styles from "./header.module.css";
import logo from "assets/images/ZazooLogo.svg";
import Avatar from "../Avatar";
import { appRoutes } from "utils/helpers";
import Menu from "Components/Menu/Menu";
import { useGetProfileQuery } from "redux/profile/profileSliceV2";

export function Header() {
  const navigate = useNavigate();

  const accessToken = localStorage.getItem("accessToken");
  const tokenZiyl = localStorage.getItem("token_ziyl");
  const {
    isUninitialized: isUninitializedProfile,
    isLoading: isLoadingProfile,
    data: profileDetailsData,
  } = useGetProfileQuery(undefined, {
    skip: !accessToken && !tokenZiyl ? true : false,
  });

  const auth = useSelector((state) => state.auth.data);

  return (
    <div id={styles.logo}>
      <Container>
        <img className={styles.logoImage} src={logo} alt="zazoo company logo" style={{cursor: 'pointer'}} onClick={() => navigate(appRoutes.dashboard)}/>
      </Container>
      <div className={styles.headerButtons}>
        {auth?.access_token && (
          <>
            {isUninitializedProfile && isLoadingProfile ? (
              <div>
                <Skeleton variant="circular" width={40} height={40} />
              </div>
            ) : (
              <div>
                <Link to={appRoutes.profile}>
                  <Avatar
                    isBase64Image
                    src={profileDetailsData?.UserProfile?.profileimage}
                  />
                </Link>
              </div>
            )}
            {/* <Menu /> */}
          </>
        )}
      </div>
    </div>
  );
}
