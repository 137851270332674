import Checkboxes from "./Checkboxes.jsx";
import Input from "./Input.jsx";
import PropTypes from "prop-types";
import React from "react";
import SelectInput from "./SelectInput";
import TextArea from "./TextArea.jsx";

function FormikController(props) {
  const { control, ...rest } = props;
  switch (control) {
    case "input":
      return <Input {...rest} />;
    case "select":
      return <SelectInput {...rest} />;
    case "checkbox":
      return <Checkboxes {...rest} />;
    case "textarea":
      return <TextArea {...rest} />;
    default:
      return null;
  }
}

FormikController.propTypes = {
  control: PropTypes.string.isRequired,
};

export default FormikController;
